import React, { useState } from "react";
import i18n from "@/i18n";

import { ItemList, ItemListColumn, ItemListRow } from "../itemList";

import useSendEmailJobs from "@/hooks/notifications/useSendEmailJobs";
import useSendEmailJob from "@/hooks/notifications/useSendEmailJob";
import useDialogModal from "@/hooks/useDialogModal";
import { Badge } from "../ui/badge";
import DialogModal from "../dialogModal";
import ToolbarMenu from "../toolbarMenu";
import { Button } from "../ui/button";
import { Clock, RefreshCcw, X } from "lucide-react";
import Breadcrumbs from "../breadcrumbs";
import { cn } from "../utils";

const { t } = i18n;

const SendEmailJobStatusBadge = ({ status = "pending", ...props }) => {
	const label = t(`send_email_job.send_grid.status.${status}`);

	const variant = {
		pending: "outline",
		processed: "outline-primary",
		processing: "outline-primary",
		delivered: "outline-success",
		not_delivered: "outline-destructive",
		failed: "outline-destructive",
	}[status];

	return (
		<Badge variant={variant} {...props}>
			{label}
		</Badge>
	);
};

const SendEmailJobsList = ({ filterByUserCommId, onClearFilter }) => {
	const { dialogElement } = useDialogModal();

	const {
		data: sendEmailJobsQuery,
		refetch: refetchSendEmailJobs,
		isFetching: isFetchingSendEmailJobs,
	} = useSendEmailJobs(filterByUserCommId);
	const sendEmailJobs = sendEmailJobsQuery?.data;

	const [selectedSendEmailJobId, setSelectedSendEmailJobId] = useState(null);
	const {
		data: selectedSendEmailJobQuery,
		isLoading: isLoadingSelectedSendEmailJob,
	} = useSendEmailJob(selectedSendEmailJobId);
	const selectedSendEmailJob = selectedSendEmailJobQuery?.data;

	return (
		<div className="flex h-full flex-col gap-4">
			<div className="flex items-center justify-between">
				<Breadcrumbs
					items={[
						{
							label: (
								<div className="flex items-center gap-2">
									<Clock /> {t("send_email_jobs.title")}
								</div>
							),
						},
					]}
				/>
				<ToolbarMenu>
					{filterByUserCommId && (
						<Button onClick={() => onClearFilter?.()}>
							<X /> {t("generic.clear_filter")}
						</Button>
					)}
					<Button
						variant="secondary"
						isLoading={isFetchingSendEmailJobs}
						onClick={async () => {
							await refetchSendEmailJobs();
						}}
					>
						<RefreshCcw /> {t("generic.refresh")}
					</Button>
				</ToolbarMenu>
			</div>
			<div
				className={cn("max-h-full overflow-hidden", {
					"rounded-xl border-2 border-primary": filterByUserCommId,
				})}
			>
				<ItemList
					columns={[
						{ id: "email", label: "Email" },
						{ id: "createdAt", label: t("send_email_job.sent_at") },
						{ id: "subject", label: "Subject" },
						{ id: "status", label: "Status" },
					]}
					columnSizing="200px 120px 1fr 90px"
					rightAlignLastColumn
					zeroStateRenderer={() => "No activity found"}
				>
					{sendEmailJobs?.map((sendEmailJob) => (
						<ItemListRow
							key={sendEmailJob.id}
							onClick={() => setSelectedSendEmailJobId(sendEmailJob.id)}
						>
							<ItemListColumn truncate>{sendEmailJob.email}</ItemListColumn>
							<ItemListColumn.Date>
								{sendEmailJob.createdAt}
							</ItemListColumn.Date>
							<ItemListColumn className="overflow-hidden">
								<span className="truncate">{sendEmailJob.subject}</span>
							</ItemListColumn>
							<ItemListColumn>
								<SendEmailJobStatusBadge
									status={sendEmailJob.sendGrid?.status}
									size="sm"
								/>
							</ItemListColumn>
						</ItemListRow>
					))}
				</ItemList>
			</div>
			<DialogModal
				open={selectedSendEmailJobId}
				size="lg"
				className="to-slate-transparent bg-gradient-to-b from-slate-200"
				isLoading={isLoadingSelectedSendEmailJob}
				onCancel={() => setSelectedSendEmailJobId(null)}
				submitText={null}
				title={
					selectedSendEmailJob
						? `${selectedSendEmailJob?.subject} - ${selectedSendEmailJob?.email}`
						: "..."
				}
				bodyRenderer={() => (
					<div
						className="tiptap mx-auto w-full max-w-[800px] rounded-lg shadow-lg"
						dangerouslySetInnerHTML={{
							__html: selectedSendEmailJob?.body,
						}}
					/>
				)}
			/>
			{dialogElement}
		</div>
	);
};

export default SendEmailJobsList;
