import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedHTMLMessage } from "react-intl";
import { fromJS, List, Map } from "immutable";
import debounce from "lodash/debounce";
import ScrollView from "../../../../dumb-components/shared/layout/scroll-view/scroll-view";
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
} from "../../../../actions/transaction.actions";
import {
	listInvestors,
	createInvestor,
	updateInvestorsList,
} from "../../../../actions/investors.actions";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import { initNewInvestor } from "../../../helpers/shares";
import DatePicker from "../../../../dumb-components/shared/datepicker/datepicker";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import Select from "../../../../dumb-components/fields/select";
import {
	validateTransactionDate,
	isRequired,
	validateInvestmentIdFrom,
	validateInvestmentIdTo,
	validateTransferOfSharesSequences,
	validateisRequiredImmutable,
} from "../../../../modules/validation.module";
import TransferOfSharesField from "./transfer-of-shares--field";
import immutableForm from "../../../framework/immutable-form";

import InvestmentsSelectContainer from "../../../../containers/shares/investments-select.container";
import InvestorsDetails from "@/components/company/shares/investors/investors-details";

import { openModal, closeModal } from "../../../../actions/modals.actions";
import FormButtonArea from "./formButtonArea";
import { SHARES_ADD_SHAREHOLDER_MODAL } from "@/constants/modals";
import i18n from "@/i18n";
import withResolvedProps from "@/hocs/withResolvedProps";
import useRestrictAccess from "@/hooks/subscriptions/useRestrictAccess";

const { t } = i18n;

const transactionType = "TRANSFER-2";

const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {},
});

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS());
}, 1000);

class TransferOfSharesForm extends Component {
	state = {
		shareholdersOptions: [], // All investors
		shareholdersFromOptions: [], // Investors that own some shares
		shareholdersToOptions: [], // Private investors
	};

	componentDidMount = () => {
		const { tmpTransaction } = this.props;

		this.props.fetchTemporaryTransaction(transactionType);
		this.props.listInvestors();
		this.setValidators(tmpTransaction);
	};

	componentDidUpdate = () => {
		const { activeEmission, tmpTransaction } = this.props;
		if (tmpTransaction) {
			this.setValidators(this.props.tmpTransaction);
			if (activeEmission) {
				const affectCompanyValuation = tmpTransaction.getIn([
					"handlerData",
					"affectCompanyValuation",
				]);
				if (affectCompanyValuation) {
					this.onChange("affectCompanyValuation", false);
				}
			}
		}
	};

	setValidators = (tmpTransaction) => {
		const { disableValidationOfField, enableValidationOfField } = this.props;
		if (
			tmpTransaction.getIn(["handlerData", "investmentIdFrom"]) &&
			tmpTransaction.getIn(["handlerData", "investmentIdTo"])
		) {
			enableValidationOfField("handlerData.sequences");
			return;
		}
		// Default validators
		disableValidationOfField("handlerData.sequences");
	};

	disableSelectedShareholder = (shareholdersOptions, valueToDisable) => {
		return shareholdersOptions.map((obj) => {
			if (obj.get("value") === valueToDisable) {
				obj = obj.set("disabled", true);
			}

			return obj;
		});
	};

	onChangeHandlerData = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } =
			this.props;
		const newTransaction = tmpTransaction.set("handlerData", val);
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChange = (field, val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		const newTransaction = tmpTransaction.setIn(["handlerData", field], val);
		resetErrors("handlerData." + field);
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeDate = (val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		const newTransaction = tmpTransaction.set("date", val);
		resetErrors("date");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeInvestmentIdTo = (val) => {
		if (val === "__createNewShareholder") {
			return this.createNewShareholder("investmentIdTo");
		}

		this.onChange("investmentIdTo", val);
	};

	onSubmit = () => {
		const { tmpTransaction, validate, createTransaction, listInvestors } =
			this.props;

		if (validate(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS(), listInvestors);
		}
	};

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(transactionType);
	};

	createNewShareholder = (event) => {
		event.preventDefault();

		initNewInvestor({
			modalOptions: {
				onCloseModal: this.addShareholder.bind(this, "investmentIdTo"),
			},
		});
	};

	addShareholder = (field, investor, result) => {
		if (result === "DISMISSED") {
			return;
		}

		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } =
			this.props;
		const transaction = tmpTransaction.setIn(
			["handlerData", field],
			investor.get("id"),
		);
		updateTransaction(transactionType, transaction);
		saveTemporaryTransaction(transactionType, transaction.toJS());
	};

	render = () => {
		const {
			transaction,
			tmpTransaction,
			i18n,
			errors,
			activeEmission,
			activeModal,
			closeModal,
			restrictDialogElement,
			restrict,
		} = this.props;
		const investmentIdFrom = tmpTransaction.getIn([
			"handlerData",
			"investmentIdFrom",
		]);
		const investmentIdTo = tmpTransaction.getIn([
			"handlerData",
			"investmentIdTo",
		]);
		const affectCompanyValuation = tmpTransaction.getIn([
			"handlerData",
			"affectCompanyValuation",
		]);

		return (
			<div className="i-content__container">
				{restrictDialogElement}
				<InvestorsDetails
					mode="MODAL"
					actionMode="CREATE"
					onCloseModal={() => closeModal(SHARES_ADD_SHAREHOLDER_MODAL)}
					isOpen={activeModal === SHARES_ADD_SHAREHOLDER_MODAL}
				/>
				<ScrollView autoHide={true} showOnHover={true}>
					<Panel
						tid="transfer_of_shares"
						className="mb-0 rounded-b-none border-b-0"
					>
						<Field
							name="date"
							tid="generic.date"
							className="col-md-5 col-md-offset-7"
							errors={errors}
						>
							<DatePicker
								hasError={errors ? errors.get("date", List()).size > 0 : false}
								calendarPlacement="bottom-end"
								id="date"
								autocomplete="off"
								value={tmpTransaction.get("date")}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<Field
							name="handlerData.investmentIdFrom"
							tid="from"
							errors={errors}
						>
							<InvestmentsSelectContainer
								visibilityMode="ACTIVE_INVESTMENTS"
								onChange={(val) => {
									this.onChange("investmentIdFrom", val);
								}}
								selectedInvestmentId={investmentIdFrom}
							/>
						</Field>

						<Field name="handlerData.investmentIdTo" tid="to" errors={errors}>
							<InvestmentsSelectContainer
								visibilityMode="ALL_INVESTMENTS"
								onChange={this.onChangeInvestmentIdTo}
								selectedInvestmentId={investmentIdTo}
								appendCreateInvestmentOption={true}
							/>
							{restrict(
								<a
									href="#"
									className="my-2 inline-flex text-blue-500 transition-colors hover:text-blue-400"
									onClick={this.createNewShareholder}
								>
									{t("add_shareholders")}
								</a>,
								{
									requiredFeature: "share-register-basic",
								},
							)}
						</Field>

						<div className="form-group--mar-btm-0 col-md-12">
							<FormattedHTMLMessage id="shares.transactions.transfer_of_shares.form.information" />
						</div>

						<Field name="handlerData.sequences" errors={errors}>
							<TransferOfSharesField
								values={tmpTransaction.getIn(
									["handlerData", "sequences"],
									List(),
								)}
								balances={transaction.get("balances", List())}
								sequences={transaction.get("sequences", List())}
								investmentIdFrom={tmpTransaction.getIn([
									"handlerData",
									"investmentIdFrom",
								])}
								investmentIdTo={tmpTransaction.getIn([
									"handlerData",
									"investmentIdTo",
								])}
								autoSelectMapToShareType={tmpTransaction.getIn(
									["handlerData", "autoSelectMapToShareType"],
									Map(),
								)}
								onChange={(val) => {
									this.onChange("sequences", val);
								}}
								onChangeHandlerData={this.onChangeHandlerData}
								handlerData={tmpTransaction.get("handlerData")}
							/>
						</Field>
						<Field
							className="form-group--mar-btm-10 col-md-12"
							name="handlerData.affectCompanyValuation"
							tid="shares.transactions.transfer_of_shares.label.company_valuation"
							errors={errors}
						>
							<Select
								placeholder={i18n.messages["select_placeholder"]}
								value={affectCompanyValuation || false}
								options={[
									{
										value: true,
										label:
											i18n.messages[
												"shares.transactions.transfer_of_shares.option.affect_company_valuation"
											],
									},
									{
										value: false,
										label:
											i18n.messages[
												"shares.transactions.transfer_of_shares.option.not_affect_company_valuation"
											],
									},
								]}
								disabled={activeEmission}
								simpleValue
								onSelect={(val) => {
									this.onChange("affectCompanyValuation", val);
								}}
							/>
						</Field>
					</Panel>
					<FormButtonArea
						onCancelClick={this.cancelTransaction}
						onSubmitClick={this.onSubmit}
					/>
				</ScrollView>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		tmpTransaction: state.transaction.getIn(
			["tmpTransaction", transactionType],
			initialTransaction,
		),
		investors: state.investors.get("list"),
		activeEmission: state.emissions.anyActiveEmissions,
		company: state.company.company,
		i18n: state.i18n,
		activeModal: state.modals.getIn(["activeModal", "name"]),
	};
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
	listInvestors,
	createInvestor,
	updateInvestorsList,
	addErrorNotification,
	addInfoNotification,
	openModal,
	closeModal,
};

const validators = fromJS({
	date: {
		tid: "generic.date",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{
				func: validateTransactionDate,
				message:
					"validation.current_transaction_date_must_be_later_then_last_transaction",
			},
		],
	},
	"handlerData.investmentIdFrom": {
		tid: "from",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{
				func: validateInvestmentIdFrom,
				message: "validation.cannot_sell_to_same_person",
			},
		],
	},
	"handlerData.investmentIdTo": {
		tid: "to",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{
				func: validateInvestmentIdTo,
				message: "validation.cannot_sell_to_same_person",
			},
		],
	},
	"handlerData.sequences": {
		tid: "shares",
		rules: [
			{ func: validateisRequiredImmutable, message: "validation.is_required" },
			{
				func: validateTransferOfSharesSequences,
				message: "validation.transfer_of_shares_sequences_price_and_shares",
			},
		],
	},
});

const TransferOfSharesFormImmutableForm = immutableForm(
	TransferOfSharesForm,
	"transferOfSharesForm",
	validators,
);
const ConnectedTransferOfSharesFormImmutableForm = connect(
	mapStateToProps,
	mapActionsToProps,
)(TransferOfSharesFormImmutableForm);

export default withResolvedProps(() => {
	const { restrict, restrictDialogElement } = useRestrictAccess();
	return { restrict, restrictDialogElement };
})(ConnectedTransferOfSharesFormImmutableForm);
