import React, { PureComponent } from "react";
import { bool, func, string } from "prop-types";
import OutdatedBrowserFullscreen from "../dmz/outdated-browser-fullscreen";
import CredentialsCarousel from "./credentialsCarousel";
import LanguagePickerDropdown from "@/components/languagePickerDropdown";

export default class Credentials extends PureComponent {
	static propTypes = {
		section: string,
		isValidBrowser: bool,
		ignoreBrowserVersion: bool,
		onIgnoreBrowserVersion: func,
		renderMiddleComponent: func,
		renderBottomComponent: func,
	};

	render = () => {
		const {
			section,
			isValidBrowser,
			ignoreBrowserVersion,
			onIgnoreBrowserVersion,
			renderMiddleComponent,
			renderBottomComponent,
		} = this.props;

		if (!isValidBrowser && !ignoreBrowserVersion) {
			return (
				<div className="flex h-full w-full">
					<OutdatedBrowserFullscreen
						onIgnoreBrowserVersion={onIgnoreBrowserVersion}
					/>
				</div>
			);
		}

		return (
			<div className="flex h-full w-full">
				<div className="relative h-full w-full lg:m-auto lg:max-h-[768px] lg:max-w-[1024px] lg:rounded-[20px] lg:bg-background lg:p-4 lg:drop-shadow">
					<div className="absolute right-0 top-0 flex items-center justify-center">
						<LanguagePickerDropdown className="rounded-tr-2xl border-none" />
					</div>
					<div className="flex h-full">
						<div className="hidden h-full w-[50%] flex-col bg-contrast bg-invono-logo bg-[length:200px] bg-[right_50px_top_28px] bg-no-repeat text-lg text-background sm:flex lg:rounded-[12px]">
							<CredentialsCarousel />
						</div>
						<div className="scrollbox flex w-[50%] flex-1 flex-col p-10 sm:p-20">
							{renderMiddleComponent?.(section)}
							{renderBottomComponent?.(section)}
						</div>
					</div>
				</div>
			</div>
		);
	};
}
