import React from "react";
import { Route, Switch, routeHoc } from "../../interfaces/router";
import InvestmentContainer from "../../containers/investments/investment.container";
import InvestmentsOverviewContainer from "../../containers/investments/overview/investments-overview.container";

const InvestmentPortfolio = ({ match, isCompany }) => {
	return (
		<div className="flex h-full flex-col p-md">
			<div className="i-content__container">
				<Switch>
					<Route
						path={`${match.path}/:companyName`}
						render={(props) => (
							<InvestmentContainer {...props} isCompany={isCompany} />
						)}
					/>
					<Route
						exact
						path={`${match.path}/`}
						render={(props) => (
							<InvestmentsOverviewContainer {...props} isCompany={isCompany} />
						)}
					/>
				</Switch>
			</div>
		</div>
	);
};

export default routeHoc(InvestmentPortfolio);
