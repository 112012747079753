import React, { useState } from "react";

import DialogModal from "@/components/dialogModal";
import Select from "@/components/select";
import { useTranslation } from "react-i18next";
import { ChartPie, Percent, SortAsc, SortDesc, User } from "lucide-react";
import { fetchOwnerReportFromApp } from "../../actions/report-pdfs.actions";
import { useDispatch } from "react-redux";
import { Button } from "../ui/button";
import useRestrictAccess from "../../hooks/subscriptions/useRestrictAccess";

const ExportOwnerReportModal = ({ onCancel }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [sortBy, setSelectedSortBy] = useState("name");
	const [sortOrder, setSelectedSortOrder] = useState("ASC");

	const { restrict, restrictDialogElement } = useRestrictAccess();

	return (
		<DialogModal
			open
			size="sm"
			title={t("investments.print_shareholders")}
			onCancel={onCancel}
			onSubmit={() => {
				dispatch(fetchOwnerReportFromApp({ sortBy, sortOrder }));
			}}
			submitButtonRenderer={({ onSubmit }) =>
				restrict(
					<Button onClick={onSubmit}>
						{t("investments.print_shareholders")}
					</Button>,
					{
						requiredFeature: "shareholders-export-owner-report",
					},
				)
			}
			bodyRenderer={() => (
				<div className="grid grid-cols-2 gap-4">
					<Select
						label={t("investors.filter.label.order")}
						value={sortBy}
						onChange={setSelectedSortBy}
						options={[
							{
								value: "name",
								label: (
									<div className="flex items-center gap-4">
										<User size={16} />
										{t("name")}{" "}
									</div>
								),
							},
							{
								value: "balance.numOfTotalShares",

								label: (
									<div className="flex items-center gap-4">
										<ChartPie size={16} />
										{t("shares")}
									</div>
								),
							},
							{
								value: "balance.ownershipPercentage",
								label: (
									<div className="flex items-center gap-4">
										<Percent size={16} />
										{t("ownership")}
									</div>
								),
							},
						]}
					/>
					<Select
						label={t("investors.filter.label.direction")}
						value={sortOrder}
						onChange={setSelectedSortOrder}
						options={[
							{
								value: "ASC",
								label: (
									<div className="flex items-center gap-4">
										<SortAsc size={16} />
										{t("investors.filter.order.asc")}
									</div>
								),
							},
							{
								value: "DESC",
								label: (
									<div className="flex items-center gap-4">
										<SortDesc size={16} />
										{t("investors.filter.order.desc")}
									</div>
								),
							},
						]}
					/>
					{restrictDialogElement}
				</div>
			)}
		/>
	);
};

export default ExportOwnerReportModal;
