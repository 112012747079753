import React, { Component } from "react";
import ToolbarContainer from "./toolbar.container";

export default class RightViewContainer extends Component {
	render = () => {
		const { children, location, basePath, params } = this.props;
		const querystr = location.search;
		return (
			<div className="flex w-full flex-col gap-6">
				<div>
					{params.id && (
						<ToolbarContainer
							basePath={basePath}
							params={params}
							querystr={querystr}
						/>
					)}
				</div>
				{children}
			</div>
		);
	};
}
