import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button.jsx";
import { useTranslation } from "react-i18next";
import Panel from "@/dumb-components/shared/panel/panel";
import { Bell } from "lucide-react";
import useUnsubscribeGroups from "@/hooks/notifications/useUnsubscribeGroups";
import useUpdateUnsubscribeGroups from "@/hooks/notifications/useUpdateUnsubscribeGroups";
import LoadingPanel from "@/components/loadingPanel";

const SG_GROUP_BY_AUDIENCE = {
	newsInvestors: 29128,
	newsCompanyAdministrators: 29129,
	newsBoardMembers: 29130,
	newsCompanyMembers: 29131,
};

const NotificationPreferences = () => {
	const { t } = useTranslation();

	const [userPreferences, setUserPreferences] = useState({
		unsubscribed: {},
		isGloballyUnsubscribed: undefined,
	});

	const {
		data: unsubscribeGroupsRequest,
		isLoading: isLoadingUnsubscribeGroups,
	} = useUnsubscribeGroups();
	const unsubscribeGroups = unsubscribeGroupsRequest?.data;

	const {
		mutate: updateUnsubscribeGroups,
		isPending: isUpdatingUnsubscribeGroups,
	} = useUpdateUnsubscribeGroups();

	useEffect(() => {
		if (
			typeof userPreferences.isGloballyUnsubscribed === "undefined" &&
			unsubscribeGroups
		) {
			setUserPreferences({
				isGloballyUnsubscribed: unsubscribeGroups.isGloballyUnsubscribed,
				unsubscribed: unsubscribeGroups.groups.reduce((acc, group) => {
					acc[group.id] = group.suppressed;
					return acc;
				}, {}),
			});
		}
	}, [unsubscribeGroups, userPreferences.isGloballyUnsubscribed]);

	return (
		<div className="flex h-full flex-col p-md">
			<Panel
				icon={<Bell />}
				title="user.notification_preferences.breadcrumbs.title"
			>
				<div className="flex flex-col gap-6 p-md">
					<div>{t("user.notification_preferences.hero_text")}</div>
					<div className="flex flex-col gap-12 rounded-lg border bg-accent p-md">
						{isLoadingUnsubscribeGroups ? (
							<LoadingPanel />
						) : (
							<>
								{Object.keys(SG_GROUP_BY_AUDIENCE).map((audience) => (
									<div key={audience} className="">
										<label className="flex gap-4">
											<input
												type="checkbox"
												checked={
													!userPreferences.unsubscribed[
														SG_GROUP_BY_AUDIENCE[audience]
													]
												}
												disabled={
													userPreferences?.isGloballyUnsubscribed ||
													isUpdatingUnsubscribeGroups
												}
												onChange={(evt) => {
													setUserPreferences({
														...userPreferences,
														unsubscribed: {
															...userPreferences.unsubscribed,
															[SG_GROUP_BY_AUDIENCE[audience]]:
																!evt.target.checked,
														},
													});
												}}
											/>
											<span className="font-bold">
												{t(
													`user.notification_preferences.newsletter.audience.${audience}.title`,
												)}
											</span>
										</label>
										<div className="ml-8">
											<span className="font-semibold">
												{t(
													`user.notification_preferences.newsletter.audience.${audience}.subtitle`,
												)}
											</span>
										</div>
										<div className="ml-8">
											{t(
												`user.notification_preferences.newsletter.audience.${audience}.description`,
											)}
										</div>
									</div>
								))}
								<div>
									<label className="flex gap-4">
										<input
											type="checkbox"
											checked={userPreferences?.isGloballyUnsubscribed}
											onChange={(evt) => {
												setUserPreferences({
													...userPreferences,
													isGloballyUnsubscribed: evt.target.checked,
												});
											}}
										/>
										<span className="font-bold">
											{t(
												"user.notification_preferences.newsletter.unsubscribeToAll.label",
											)}
										</span>
									</label>
									<div className="ml-8">
										{t(
											"user.notification_preferences.newsletter.unsubscribeToAll.description",
										)}
									</div>
								</div>
							</>
						)}
					</div>
					<div>
						<Button
							onClick={() => updateUnsubscribeGroups(userPreferences)}
							disabled={isLoadingUnsubscribeGroups}
							isLoading={isUpdatingUnsubscribeGroups}
						>
							{t("user.notification_preferences.update_button.label")}
						</Button>
					</div>
				</div>
			</Panel>
		</div>
	);
};

export default NotificationPreferences;
