import React, { Component } from "react";
import SharesSetupStartpage from "./shares-setup--startpage";
import SharesSetup from "./shares-setup";

class Shares extends Component {
	render() {
		const { match } = this.props;

		return (
			<div className="flex h-full flex-col p-md">
				{match.params.setupType ? (
					<SharesSetup
						match={match}
						location={this.props.location}
						history={this.props.history}
					/>
				) : (
					<SharesSetupStartpage
						match={match}
						location={this.props.location}
						history={this.props.history}
					/>
				)}
			</div>
		);
	}
}

export default Shares;
