import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogBody, DialogContent } from "../ui/dialog";
import LoadingPanel from "../loadingPanel";
import { useTranslation } from "react-i18next";

const ProcessingBar = ({ fakeLoadingMessage, fakeLoading }) => {
	const { t } = useTranslation();

	return (
		<Dialog open={fakeLoading}>
			<DialogContent hideCloseButton className="w-[400px]">
				<DialogBody>
					<LoadingPanel size={36} />
					<div className="mt-2 text-center text-lg">
						{fakeLoadingMessage && t(fakeLoadingMessage)}
					</div>
				</DialogBody>
			</DialogContent>
		</Dialog>
	);
};

function mapStateToProps(state) {
	return {
		fakeLoading: state.notify.fakeLoading,
		fakeLoadingMessage: state.notify.fakeLoadingMessage,
	};
}

const mapActionsToProps = {};

const ProcessingBarConnected = connect(
	mapStateToProps,
	mapActionsToProps,
)(ProcessingBar);
export default ProcessingBarConnected;
