import React, { useState } from "react";
import DialogModal from "@/components/dialogModal";
import useShareholders from "@/hooks/shares/useShareholders";
import { ItemList, ItemListColumn, ItemListRow } from "@/components/itemList";
import { Button } from "@/components/ui/button";
import { cn } from "@/components/utils";
import LoadingPanel from "@/components/loadingPanel";
import { User, Building2, Landmark, X } from "lucide-react";
import usePagination from "@/hooks/usePagination";
import withTooltip from "@/hocs/withTooltip";
import i18n from "@/i18n";
import Avatar from "./avatar";

const { t } = i18n;

const UserTT = withTooltip()(User);
const Building2TT = withTooltip()(Building2);
const LandmarkTT = withTooltip()(Landmark);

const getOwnerTypeIcon = (ownerType) => {
	switch (ownerType) {
		case "company":
			return (
				<Building2TT size={18} title={t("shares.type_of_owner.company")} />
			);
		case "companyContact":
			return <UserTT size={18} title="Company contact" />;
		case "private":
			return <UserTT size={18} title={t("shares.type_of_owner.private")} />;
		case "capitalInsurance":
			return (
				<LandmarkTT
					size={18}
					title={t("people_modal.investor_type_of_owner.capital_insurance")}
				/>
			);
		case "capital":
			return (
				<LandmarkTT
					size={18}
					title={t("people_modal.investor_type_of_owner.capital_insurance")}
				/>
			);
	}
};

const FilterBoolean = ({ label, value, onChange }) => {
	return (
		<Button
			size="sm"
			className={cn(
				"rounded-full border-2 border-primary/30 text-primary hover:bg-primary/10 hover:text-primary",
				{
					"border-primary bg-primary/80 text-background hover:bg-primary/90 hover:text-background":
						value,
				},
			)}
			variant={value ? "default" : "outline"}
			onClick={onChange}
		>
			{label}
		</Button>
	);
};

const useFilterPeople = ({ sources, filterGroups = [], excludeIds = [] }) => {
	// Validate groups
	if (!Array.isArray(filterGroups)) {
		throw new Error("filterGroups must be an array");
	}
	filterGroups.forEach((group) => {
		if (!Array.isArray(group.filters)) {
			throw new Error("filterGroups.filters must be an array");
		}
		if (!["and", "or"].includes(group.method)) {
			throw new Error("filterGroups.method must be 'and' or 'or'");
		}
	});

	const filtersFlat = filterGroups.flatMap((g) => g.filters);

	const [filterValues, setFilterValues] = useState(() =>
		filtersFlat.reduce(
			(acc, filterConfig) => ({
				...acc,
				[filterConfig.id]: filterConfig.defaultValue,
			}),
			{},
		),
	);

	const configByFilterId = filtersFlat.reduce(
		(acc, filterConfig) => ({ ...acc, [filterConfig.id]: filterConfig }),
		{},
	);

	const toggleFilter = (filterId) => {
		const filterValue = filterValues[filterId];

		setFilterValues((prev) => ({
			...prev,
			[filterId]: !filterValue,
		}));
	};

	const addToFilter = (filterId, value) => {
		const filterConfig = configByFilterId[filterId];

		if (filterConfig.type !== "list") {
			throw new Error(`Filter ${filterId} is not a list filter`);
		}

		if (typeof value === "undefined") {
			throw new Error(`Value is required for filter ${filterId}`);
		}

		if (filterValues[filterId]?.includes?.(value)) return;

		setFilterValues((prev) => ({
			...prev,
			[filterId]: [...prev[filterId], value],
		}));
	};

	const removeFromFilter = (filterId, value) => {
		const filterConfig = configByFilterId[filterId];

		if (filterConfig.type !== "list") {
			throw new Error(`Filter ${filterId} is not a list filter`);
		}

		if (typeof value === "undefined") {
			throw new Error(`Value is required for filter ${filterId}`);
		}

		setFilterValues((prev) => ({
			...prev,
			[filterId]: prev[filterId].filter((v) => v !== value),
		}));
	};

	const getFilterValue = (filterId) => filterValues[filterId];

	const renderFilterControl = (filterId) => {
		const config = configByFilterId[filterId];

		if (!config) return null;

		if (config.type === "list") {
			const values = getFilterValue(filterId);

			const valueRenderer = config.valueRenderer ?? ((i) => i);

			return (
				<div className="flex flex-wrap gap-1">
					{values.map((v) => (
						<FilterBoolean
							key={v}
							label={
								<div className="flex items-center gap-1">
									<X size={12} />
									{valueRenderer(v)}
								</div>
							}
							value={true}
							onChange={() => removeFromFilter(filterId, v)}
						/>
					))}
				</div>
			);
		}

		if (config.type === "boolean") {
			return (
				<FilterBoolean
					label={config.label}
					value={getFilterValue(filterId)}
					onChange={() => toggleFilter(filterId)}
				/>
			);
		}
	};

	const _isFilterEnabled = (filterId) => {
		const filterConfig = configByFilterId[filterId];
		if (filterConfig.type === "list") {
			return filterValues[filterId].length > 0;
		} else if (filterConfig.type === "boolean") {
			return filterValues[filterId];
		}
		return false;
	};

	const _applyFilter = (inputData, filterConfig) => {
		return inputData.map((item) => {
			let _isFiltered;

			switch (filterConfig.type) {
				case "list": {
					_isFiltered = filterConfig.fn(item, filterValues[filterConfig.id]);
					break;
				}
				case "boolean": {
					_isFiltered = filterConfig.fn(item);
					break;
				}
				default:
					throw new Error(`Unknown filter type ${filterConfig.type}`);
			}

			if (typeof _isFiltered !== "boolean") {
				throw new Error(
					`Filter '${filterConfig.id}' did not return a boolean value`,
				);
			}

			_isFiltered = item._isFiltered || _isFiltered; // the filter is applied as OR

			return {
				...item,
				_isFiltered: _isFiltered,
			};
		});
	};

	const sourceData = Object.values(sources)
		.flatMap((source) => source())
		.filter((item) => !excludeIds.includes(item.id));

	const filteredData = sourceData;
	const filterGroupResults = []; // { group, data, anyFiltersApplied }[]

	// Generate results for the filter groups
	for (let i = 0; i < filterGroups.length; i++) {
		const filterGroup = filterGroups[i];
		const filterGroupFilters = [...filterGroup.filters];
		let groupFilteredData = sourceData;

		let filter;
		while ((filter = filterGroupFilters.shift())) {
			const filterConfig = configByFilterId[filter.id];

			if (!_isFilterEnabled(filter.id)) continue;

			groupFilteredData = _applyFilter(groupFilteredData, filterConfig);
		}

		filterGroupResults.push({
			anyFiltersApplied: groupFilteredData !== sourceData,
			group: filterGroup,
			data: groupFilteredData,
		});
	}

	// Intersect the groups
	if (filterGroupResults.length) {
		for (let i = 0; i < sourceData.length; i++) {
			const isFiltered = filterGroupResults.reduce((prev, groupResult) => {
				const { data, group, anyFiltersApplied } = groupResult;

				// In case this filter group is not activated
				if (!anyFiltersApplied) return prev;

				// In case this is the first filter group activated in the chain
				if (typeof prev === "undefined") return data[i]._isFiltered;

				// In case of AND
				if (group.method === "and") return prev && data[i]._isFiltered;

				// In case of OR
				if (group.method === "or") return prev || data[i]._isFiltered;
			}, undefined);

			filteredData[i]._isFiltered = isFiltered;
		}
	}

	return {
		addToFilter,
		removeFromFilter,
		toggleFilter,
		getFilterValue,
		renderFilterControl,
		data: filteredData,
	};
};

const FilterPeople = ({ onCancel, onSubmit, excludeIds }) => {
	// const { data: investmentContactTags, isLoading: isLoadingContactTags } =
	//   useInvestmentContactsTags();
	// const shareholderContactTags = investmentContactTags?.data ?? [];

	const { data: investmentsQuery, isLoading } = useShareholders();
	const shareholders = investmentsQuery?.data ?? [];

	const {
		renderFilterControl,
		data: filteredPeople,
		addToFilter,
		getFilterValue,
		removeFromFilter,
	} = useFilterPeople({
		excludeIds,
		filterGroups: [
			{
				method: "and",
				filters: [
					{
						id: "ownerType_company",
						label: t("shares.type_of_owner.company"),
						type: "boolean",
						fn: (item) => item.ownerType === "company",
						defaultValue: false,
					},
					// {
					//   id: "ownerType_companyContact",
					//   label: "Company contact",
					//   type: "boolean",
					//   fn: (item) => item.ownerType === "companyContact",
					//   defaultValue: false,
					// },
					{
						id: "ownerType_private",
						label: t("people_modal.investor_type_of_owner.private"),
						type: "boolean",
						fn: (item) => item.ownerType === "private",
						defaultValue: false,
					},
					{
						id: "ownerType_capitalInsurance",
						label: t("people_modal.investor_type_of_owner.capital_insurance"),
						type: "boolean",
						fn: (item) => item.ownerType === "capitalInsurance",
						defaultValue: false,
					},
				],
			},
			{
				method: "and",
				filters: [
					{
						id: "ownerStatus_current",
						label: t("filter_people.shareholders.owner_status.current"),
						type: "boolean",
						fn: (item) => item.ownerStatus === "current",
						defaultValue: false,
					},
					{
						id: "ownerStatus_never",
						label: t("filter_people.shareholders.owner_status.never"),
						type: "boolean",
						fn: (item) => item.ownerStatus === "never",
						defaultValue: false,
					},
					{
						id: "ownerStatus_former",
						label: t("filter_people.shareholders.owner_status.former"),
						type: "boolean",
						fn: (item) => item.ownerStatus === "former",
						defaultValue: false,
					},
				],
			},
			{
				method: "and",
				filters: [
					{
						id: "isRegistered_yes",
						label: t("filter_people.is_registered.true"),
						type: "boolean",
						fn: (item) => item.isRegistered,
						defaultValue: false,
					},
					{
						id: "isRegistered_no",
						label: t("filter_people.is_registered.false"),
						type: "boolean",
						fn: (item) => !item.isRegistered,
						defaultValue: false,
					},
				],
			},
			{
				method: "or",
				filters: [
					{
						id: "individualIncludes",
						type: "list",
						fn: (item, list) => list.includes(item.id),
						defaultValue: [],
						valueRenderer: (v) => filteredPeople.find((p) => p.id === v)?.name,
					},
				],
			},
			{
				method: "and",
				filters: [
					{
						id: "individualExcludes",
						type: "list",
						fn: (item, list) => !list.includes(item.id),
						defaultValue: [],
						valueRenderer: (v) => filteredPeople.find((p) => p.id === v)?.name,
					},
				],
			},
			// {
			//   id: "isRegistered",
			//   label: "Invono member",
			//   type: "boolean",
			//   fn: (item) => item.isRegistered,
			//   defaultValue: false,
			// },
			// ...(shareholderContactTags ?? []).map((tag) => ({
			//   id: `hasTag_${tag}`,
			//   label: tag,
			//   type: "boolean",
			//   fn: (item) => item.contactTags?.includes(tag),
			// })),
		],
		sources: {
			shareholders: () =>
				shareholders?.flatMap?.((shareholder) => {
					return [
						{
							id: shareholder.id,
							investorId: shareholder.investorId,
							avatarId: shareholder.investorId ?? shareholder.id,
							name: shareholder.investorInformation?.name,
							displayName: shareholder.investorInformation?.name,
							email:
								shareholder.investorTypeOfOwner === "capitalInsurance"
									? shareholder.captialIncuranceOwnerInformation?.email
									: shareholder.investorInformation?.email,
							ownerType: shareholder.investorTypeOfOwner,
							isRegistered: shareholder.computed.isActiveUser,
							ownerStatus: shareholder.details?.hasShares
								? "current"
								: shareholder.isDeletable
								? "never"
								: "former",
							contactTags: [],
						},
						// ...(shareholder.investorInformation?.contacts?.map((contact) => ({
						// 	name: contact.name,
						// 	email: contact.email,
						// 	displayName: (
						// 		<div className="flex inline-flex items-center gap-2">
						// 			{shareholder.investorInformation?.name}{" "}
						// 			<ArrowRight size={16} />
						// 			{contact.name}
						// 		</div>
						// 	),
						// 	ownerType: "companyContact",
						// 	isRegistered: false,
						// 	contactTags: contact.tags,
						// })) ?? []),
					];
				}),
		},
	});
	const selectedPeople = filteredPeople.filter((p) => p._isFiltered);

	const { pageData: pagedPeople, paginationElement } =
		usePagination(filteredPeople);

	return (
		<DialogModal
			open
			size="xl"
			title={t("filter_people.submit.label")}
			onCancel={onCancel}
			submitDisabled={selectedPeople.length === 0}
			submitText={t("filter_people.submit.label")}
			onSubmit={() => {
				onCancel();
				onSubmit(selectedPeople);
			}}
			bodyRenderer={() => (
				<div className="flex h-full gap-6">
					{/* {isLoadingContactTags ? (
              <LoadingPanel />
            ) : ( */}
					<div className="flex w-[280px] flex-col gap-8">
						<div className="flex flex-col gap-8 overflow-hidden">
							{getFilterValue("individualIncludes").length > 0 && (
								<>
									<h2 className="font-semibold">
										{t("filter_people.include_individuals")}
									</h2>
									<div className="flex flex-wrap gap-2">
										{renderFilterControl("individualIncludes")}
									</div>
								</>
							)}
							<h2 className="font-semibold">{t("type_of_owner")}</h2>
							<div className="flex flex-wrap gap-2">
								{renderFilterControl("ownerType_company")}
								{renderFilterControl("ownerType_companyContact")}
								{renderFilterControl("ownerType_private")}
								{renderFilterControl("ownerType_capitalInsurance")}
							</div>
							<h2 className="font-semibold">
								{t("investors.filter.label.status")}
							</h2>
							<div className="flex flex-wrap gap-2">
								{renderFilterControl("ownerStatus_current")}
								{renderFilterControl("ownerStatus_former")}
								{renderFilterControl("ownerStatus_never")}
							</div>
							<h2 className="font-semibold">
								{t("filter_people.is_registered.label")}
							</h2>
							<div className="flex flex-wrap gap-2">
								{renderFilterControl("isRegistered_yes")}
								{renderFilterControl("isRegistered_no")}
							</div>
							{getFilterValue("individualExcludes").length > 0 && (
								<>
									<h2 className="font-semibold">
										{t("filter_people.exclude_individuals")}
									</h2>
									<div className="flex flex-wrap gap-2">
										{renderFilterControl("individualExcludes")}
									</div>
								</>
							)}
							{/* <h2 className="font-semibold">Member status</h2>
                <div className="flex flex-wrap gap-2">
                  {renderFilterControl("isRegistered")}
                </div> */}
							{/* <h2 className="font-semibold">Contact tags</h2>
                <div className="flex flex-wrap gap-2">
                  {shareholderContactTags.map((tag) =>
                    renderFilterControl(`hasTag_${tag}`),
                  )}
                </div> */}
						</div>
						<div className="flex items-center justify-center border-t border-slate-100 pt-6 text-md">
							{filteredPeople.reduce(
								(acc, p) => acc + Number(p._isFiltered ?? 0),
								0,
							)}{" "}
							/ {filteredPeople.length}{" "}
							{t("filter_people.people").toLocaleLowerCase()}
						</div>
					</div>
					{/* )} */}
					<div className="flex-1">
						{isLoading ? (
							<LoadingPanel />
						) : (
							<div className="flex h-full flex-col gap-4">
								<div className="max-h-full overflow-hidden">
									<ItemList
										columns={[
											{ id: "checkbox", label: "" },
											{ id: "name", label: t("name") },
											{ id: "email", label: t("email") },
											{
												id: "ownerStatus",
												label: t("investors.filter.label.status"),
											},
											{
												id: "isRegistered",
												label: t("people_modal.grid.headers.is_member"),
											},
											{
												id: "ownerType",
												label: t("owner_type"),
											},
										]}
										columnSizing="15px 2fr 2fr 120px 1fr 95px"
										isSortable
										rightAlignLastColumn
									>
										{pagedPeople.map((person) => {
											const handleCheckPerson = (checked) => {
												if (!checked) {
													const includedIndividuals =
														getFilterValue("individualIncludes");

													if (includedIndividuals.includes(person.id)) {
														removeFromFilter("individualIncludes", person.id);
													} else {
														addToFilter("individualExcludes", person.id);
													}
												} else {
													const excludedIndividuals =
														getFilterValue("individualExcludes");

													if (excludedIndividuals.includes(person.id)) {
														removeFromFilter("individualExcludes", person.id);
													} else {
														addToFilter("individualIncludes", person.id);
													}
												}
											};

											return (
												<ItemListRow
													key={person.name}
													isChecked={person._isFiltered}
													onClick={() => handleCheckPerson(!person._isFiltered)}
												>
													<ItemListColumn.Checkbox
														onCheckedChange={handleCheckPerson}
													/>
													<ItemListColumn className="flex items-center gap-2 truncate">
														<Avatar
															size="sm"
															id={person.avatarId}
															name={person.name}
															type={person.ownerType}
														/>
														<div className="truncate">{person.displayName}</div>
													</ItemListColumn>
													<ItemListColumn truncate>
														{person.email}
													</ItemListColumn>
													<ItemListColumn>
														{t(
															`filter_people.shareholders.owner_status.${person.ownerStatus}`,
														)}
													</ItemListColumn>
													<ItemListColumn>
														{t(
															`filter_people.is_registered.${person.isRegistered}`,
														)}
													</ItemListColumn>
													<ItemListColumn>
														{getOwnerTypeIcon(person.ownerType)}
													</ItemListColumn>
												</ItemListRow>
											);
										})}
									</ItemList>
								</div>
								{paginationElement}
							</div>
						)}
					</div>
				</div>
			)}
		/>
	);
};

export default FilterPeople;
