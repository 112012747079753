import React from "react";
import useSubscription from "@/hooks/subscriptions/useSubscription";
import LoadingPanel from "../loadingPanel";
import moment from "@/modules/moment.module";
import { useTranslation } from "react-i18next";
import useDocumentsHelper from "@/hooks/documents/useDocumentsHelper";
import useSubscriptionPlans from "@/hooks/subscriptions/useSubscriptionPlans";
import useSubscriptionSchedule from "@/hooks/subscriptions/useSubscriptionSchedule";
import { Button } from "../ui/button";
import useUndoScheduledSubscriptionPlan from "@/hooks/subscriptions/useUndoScheduledSubscriptionPlan";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import { Info, RefreshCwOff } from "lucide-react";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";

import { cn } from "../utils";
import useCountInvestors from "@/hooks/shares/useCountInvestors";

const formatStorageString = (storageGb) =>
	storageGb < 1
		? `${(storageGb * 1024).toFixed(0)} MB`
		: `${storageGb.toFixed(0)} GB`;

const SubscriptionBillingSummary = () => {
	const { t } = useTranslation();

	const companyId = useCompanyIdFromUrl();

	const { data: subscriptionQuery } = useSubscription();
	const subscription = subscriptionQuery?.data;
	const stripeSubscription = subscription?.stripeData?.subscriptions?.data[0];

	const { data: subscriptionPlansRequest } = useSubscriptionPlans();
	const subscriptionPlans = subscriptionPlansRequest?.data;

	const { data: subscriptionScheduleRequest } = useSubscriptionSchedule();
	const subscriptionSchedule = subscriptionScheduleRequest?.data;

	const { data: documentsHelperRequest } = useDocumentsHelper();
	const documentsHelper = documentsHelperRequest?.data;

	const { data: countInvestorsRequest } = useCountInvestors();
	const activeShareholderCount =
		countInvestorsRequest?.data?.totalNumOfInvestors;
	const totalAllowedShareholders =
		+subscription?.accountPlan.metadata.maxNumberOfShareholders;

	const scheduledPhase =
		subscriptionSchedule?.phases?.length > 1 &&
		subscriptionSchedule.phases[subscriptionSchedule.phases.length - 1];

	const scheduledPlan = scheduledPhase?.plans?.[0]?.plan;
	const toBeCanceled = stripeSubscription?.cancel_at > 0;

	const {
		mutate: undoScheduledSubscriptionPlan,
		isPending: isUndoingScheduledSubscriptionPlan,
	} = useUndoScheduledSubscriptionPlan(companyId);

	if (!subscription || !documentsHelperRequest || !subscriptionPlansRequest) {
		return <LoadingPanel />;
	}

	const scheduledAccountPlan = [
		...(subscriptionPlans.account?.month ?? []),
		...(subscriptionPlans.account?.year ?? []),
	].find(({ id }) =>
		toBeCanceled ? id === "freeAccount" : id === scheduledPlan,
	);

	const currentPeriodEndTimestamp = stripeSubscription?.current_period_end;

	const currentPeriodEnd =
		currentPeriodEndTimestamp &&
		moment.unix(currentPeriodEndTimestamp).format("LL");

	const coupon = subscription?.stripeData?.discount?.coupon;

	const basePrice = subscription.accountPlan.amount / 100;
	let discountedPrice = basePrice;

	if (typeof coupon?.amount_off === "number")
		discountedPrice -= coupon.amount_off / 100;
	else if (typeof coupon?.percent_off === "number")
		discountedPrice -= (coupon.percent_off / 100) * basePrice;

	const discountedDifference = basePrice - discountedPrice;

	const addedTax = discountedPrice * 0.25;
	const taxedTotalPrice = discountedPrice + addedTax;

	const isMonthly = subscription.accountPlan.interval === "month";
	const isPlanChangeScheduled = !!scheduledAccountPlan;

	return (
		<div>
			<div
				className={cn(
					"dialog-sheen flex flex-col gap-4 rounded-lg bg-contrast bg-top p-4 text-contrast-foreground",
					{ "rounded-b-none": !!isPlanChangeScheduled },
				)}
			>
				<div className="grid grid-cols-2 even:[&>div]:text-right">
					<div>
						{t(
							`subscriptions.plans.company-account.${subscription.accountPlan.metadata.plan}`,
						)}
						{" - "}
						{isMonthly
							? t("subscriptions.plans.monthly")
							: t("subscriptions.plans.yearly")}
					</div>
					<div>{localeFormatNumber(basePrice, "currency")}</div>
					{discountedPrice !== basePrice && (
						<>
							<div>
								{t("subscriptions.subscribed_product.total.discount")}{" "}
								{typeof coupon?.amount_off === "number"
									? `(-${localeFormatNumber(
											coupon.amount_off / 100,
											"currency",
									  )})`
									: `(-${coupon.percent_off}%)`}
							</div>
							<div>-{localeFormatNumber(discountedDifference, "currency")}</div>
						</>
					)}
					<div>
						{t("subscriptions.summary.vat", {
							percentage: 25,
							amount: localeFormatNumber(discountedPrice, "currency"),
						})}
					</div>
					<div>{localeFormatNumber(addedTax, "currency")}</div>
					<div className="pt-2 text-lg font-bold">
						{isMonthly
							? t("subscriptions.subscribed_product.total.month")
							: t("subscriptions.subscribed_product.total.year")}
					</div>
					<div className="pt-2 text-lg font-bold">
						{localeFormatNumber(taxedTotalPrice, "currency")}
					</div>
				</div>
				<div className="grid grid-cols-2 even:[&>div]:text-right">
					{currentPeriodEnd && (
						<>
							<div>{t("subscriptions.next_billing_cycle")}</div>
							<div>{currentPeriodEnd}</div>
						</>
					)}
					<div>{t("subscriptions.disk_space.title")}</div>
					<div>
						{formatStorageString(+documentsHelper.usedStorageSpace)}
						{" / "}
						{formatStorageString(+subscription.accountPlan.metadata.storage)}
					</div>
					<div>{t("service.shareholders")}</div>
					<div>
						<span
							className={cn({
								"font-bold text-red-300":
									activeShareholderCount > totalAllowedShareholders,
							})}
						>
							{activeShareholderCount}
						</span>
						{" / "}
						{totalAllowedShareholders > 1000
							? t("unlimited")
							: totalAllowedShareholders}
					</div>
				</div>
			</div>
			{isPlanChangeScheduled && (
				<div className="flex justify-between gap-4 rounded-lg rounded-t-none border border-t-0 border-edge px-4 py-6">
					<div>
						<Info size={30} />
					</div>
					<div className="flex-1">
						{t("subscriptions.plan_change_scheduled.title", {
							date: toBeCanceled
								? moment.unix(stripeSubscription.cancel_at).format("LL")
								: moment.unix(scheduledPhase.start_date).format("LL"),
						})}
						:
						<div className="text-lg font-bold">
							{t(
								`subscriptions.plans.company-account.${scheduledAccountPlan.metadata.plan}`,
							)}
							{scheduledAccountPlan.metadata.plan !== "free" &&
								(scheduledAccountPlan.interval === "month"
									? ` - ${t("subscriptions.plans.monthly")}`
									: ` - ${t("subscriptions.plans.yearly")}`)}
						</div>
					</div>
					<div>
						<Button
							isLoading={isUndoingScheduledSubscriptionPlan}
							onClick={undoScheduledSubscriptionPlan}
							variant="secondary"
						>
							<RefreshCwOff /> {t("subscription.reset_plan")}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default SubscriptionBillingSummary;
