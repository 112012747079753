import React from "react";
import SplitView from "../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../dumb-components/shared/layout/scroll-view/scroll-view";
import PlansContainer from "./plans.container";
import CancelAccountContainer from "./cancel-account.container";
import PrepaidContainer from "./prepaid.container";
import InfoAboutPotAfterSubModalContainer from "./info-about-pot-after-sub-modal.container";

import useSubscription from "@/hooks/subscriptions/useSubscription";
import useSubscriptionPlans from "@/hooks/subscriptions/useSubscriptionPlans";
import useDocumentsHelper from "@/hooks/documents/useDocumentsHelper";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import LoadingPanel from "@/components/loadingPanel";
import PaymentDetailsPanel from "@/dumb-components/subscriptions/payment-details-panel/payment-details-panel";

function SubscriptionsContainer() {
	const companyId = useCompanyIdFromUrl();

	const { data: subscriptionRequest, isLoading: isFetchingSubscription } =
		useSubscription();

	const { data: subscriptionPlansRequest, isLoading: isFetchingPlans } =
		useSubscriptionPlans();

	const { data: documentsHelperRequest, isLoading: isFetchingDocumentsHelper } =
		useDocumentsHelper();

	const subscription = subscriptionRequest?.data;
	const subscriptionPlans = subscriptionPlansRequest?.data;
	const documentsHelper = documentsHelperRequest?.data;

	if (
		!companyId ||
		isFetchingDocumentsHelper ||
		isFetchingSubscription ||
		isFetchingPlans
	) {
		return <LoadingPanel />;
	}

	const renderLeftSplitView = () => {
		return (
			<div className="w-full overflow-auto">
				<PlansContainer
					subscription={subscription}
					plans={subscriptionPlans}
					isSharingFolders={documentsHelper.isSharingFolders}
				/>
				<PrepaidContainer />
				<CancelAccountContainer />
			</div>
		);
	};

	const renderRightSplitView = () => {
		const isFreeOfCharge = subscription?.isFreeOfCharge;

		if (isFreeOfCharge) return null;

		return (
			<ScrollView autoHide={true} showOnHover={true}>
				<PaymentDetailsPanel />
			</ScrollView>
		);
	};

	return (
		<div className="flex h-full flex-col p-md">
			<SplitView
				leftComponent={renderLeftSplitView}
				rightComponent={renderRightSplitView}
			/>

			<InfoAboutPotAfterSubModalContainer />
		</div>
	);
}

export default SubscriptionsContainer;
