import React, { PureComponent } from "react";
import { Button } from "@/components/ui/button";
import { bool, func, oneOf, string } from "prop-types";
import { Input } from "@/components/ui/input";
import { map } from "react-immutable-proptypes";
import { Map } from "immutable";
import ErrorMessage from "../error-message";
import { Checkbox } from "@/components/ui/checkbox";

import {
	NAME_FIELD,
	EMAIL_FIELD,
	PASSWORD_FIELD,
	PASSWORD_VERIFY_FIELD,
	TOS_FIELD,
	CODE_FIELD,
	PHONE_FIELD,
} from "../../../constants/credentials";

import i18n from "@/i18n";
import VerificationCodeInput from "./verificationCodeInput";
import { Trans } from "react-i18next";
import { LockOpen } from "lucide-react";

const { t } = i18n;

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickForgot: func,
		onToggleRememberPass: func,
		onClickSignUp: func,
		onClickVerify: func,
		onClickCancel: func,
		mode: oneOf(["signup", "verify"]),
		name: string,
		email: string,
		password: string,
		passwordVerify: string,
		phone: string,
		tos: bool,
		hasError: map,
		actionBtnLoading: bool,
		isVerifyMode: bool,
		onOpenTos: func,
	};

	static defaultProps = {
		rememberDetails: false,
		tos: false,
		mode: "signup",
		hasError: Map(),
		name: "",
		email: "",
		password: "",
		passwordVerify: "",
		phone: "",
	};

	componentDidMount() {
		window.addEventListener("focus", this.onWindowFocus);
	}

	componentWillUnmount() {
		window.removeEventListener("focus", this.onWindowFocus);
	}

	onWindowFocus = () => {
		const { isVerifyMode } = this.props;

		if (!isVerifyMode || !this.verificationCodeInput) return;

		// auto-focus when window is focused so user can paste code conveniently
		this.verificationCodeInput.value = "";
		this.verificationCodeInput.focus();
	};

	renderNameInput = () => {
		const {
			hasError,
			onClickSignUp,
			onChange,
			name,
			isVerifyMode,
			actionBtnLoading,
		} = this.props;

		return (
			<div>
				<label htmlFor="name">{t("credentials_name")}</label>
				<Input
					autoFocus
					id="name"
					value={name}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignUp()}
					disabled={isVerifyMode || actionBtnLoading}
					onChange={(evt) => onChange(NAME_FIELD, evt.target.value)}
					validationMessage={
						hasError.get(NAME_FIELD)?.size > 0 &&
						hasError.get(NAME_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderEmailInput = () => {
		const {
			hasError,
			onClickSignUp,
			onChange,
			email,
			isVerifyMode,
			actionBtnLoading,
		} = this.props;

		return (
			<div>
				<label htmlFor="email">{t("credentials.signup.label.email")}</label>
				<Input
					id="email"
					value={email}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignUp()}
					disabled={isVerifyMode || actionBtnLoading}
					onChange={(evt) => onChange(EMAIL_FIELD, evt.target.value)}
					validationMessage={
						hasError.get(EMAIL_FIELD)?.size > 0 &&
						hasError.get(EMAIL_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderPasswordInput = () => {
		const {
			hasError,
			onClickSignUp,
			onChange,
			password,
			isVerifyMode,
			actionBtnLoading,
		} = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<div>
				<label htmlFor="password">{t("credentials_password")}</label>
				<Input
					id="password"
					type="password"
					value={password}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignUp()}
					disabled={actionBtnLoading}
					onChange={(evt) => onChange(PASSWORD_FIELD, evt.target.value)}
					validationMessage={
						hasError.get(PASSWORD_FIELD)?.size > 0 &&
						hasError.get(PASSWORD_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderPasswordVerifyInput = () => {
		const {
			hasError,
			onClickSignUp,
			onChange,
			passwordVerify,
			isVerifyMode,
			actionBtnLoading,
		} = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<div>
				<label htmlFor="passwordVerify">
					{t("credentials_confirm_password")}
				</label>
				<Input
					id="passwordVerify"
					type="password"
					value={passwordVerify}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignUp()}
					disabled={actionBtnLoading}
					onChange={(evt) => onChange(PASSWORD_VERIFY_FIELD, evt.target.value)}
					validationMessage={
						hasError.get(PASSWORD_VERIFY_FIELD)?.size > 0 &&
						hasError.get(PASSWORD_VERIFY_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderTosCheckbox = () => {
		const {
			hasError,
			tos,
			isVerifyMode,
			actionBtnLoading,
			onOpenTos,
			onChange,
		} = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<div className="flex items-center gap-3">
				<Checkbox
					id="accept-tos"
					checked={tos}
					onCheckedChange={(checked) => onChange(TOS_FIELD, checked)}
					disabled={actionBtnLoading}
					validationMessage={
						hasError.get(TOS_FIELD)?.size > 0 &&
						hasError.get(TOS_FIELD).map(t).toJS()
					}
					label={
						<Trans
							i18nKey="crendentials.accept_tos.label"
							components={[
								<Button
									className="p-0"
									key="tos_link"
									variant="link"
									onClick={onOpenTos}
								/>,
							]}
						/>
					}
				/>
			</div>
		);
	};

	renderPhoneInput = () => {
		const {
			onClickSignUp,
			onChange,
			phone,
			isVerifyMode,
			actionBtnLoading,
			hasError,
		} = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<div>
				<label htmlFor="phone">{t("credentials.verify.phone.label")}</label>
				<Input
					id="phone"
					value={phone}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignUp()}
					disabled={isVerifyMode || actionBtnLoading}
					onChange={(evt) => onChange(PHONE_FIELD, evt.target.value)}
					validationMessage={
						hasError.get(PHONE_FIELD)?.size > 0 &&
						hasError.get(PHONE_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderVerifyCodeInput = () => {
		const { onClickVerify, onChange, code, isVerifyMode, email } = this.props;

		if (!isVerifyMode) {
			return null;
		}

		return (
			<VerificationCodeInput
				ref={(ref) => {
					this.verificationCodeInput = ref;
				}}
				email={email}
				value={code}
				onChange={(code) => {
					onChange(CODE_FIELD, code);
				}}
				onComplete={onClickVerify}
			/>
		);
	};

	render = () => {
		const {
			onClickSignUp,
			onClickVerify,
			onClickCancel,
			isVerifyMode,
			actionBtnLoading,
			translationId,
		} = this.props;

		return (
			<div className="flex flex-col gap-6">
				{this.renderNameInput()}
				{this.renderEmailInput()}
				{this.renderPhoneInput()}
				{this.renderPasswordInput()}
				{this.renderPasswordVerifyInput()}
				{this.renderTosCheckbox()}
				{this.renderVerifyCodeInput()}

				<ErrorMessage tid={translationId} />

				{isVerifyMode ? (
					<div className="flex justify-stretch gap-2">
						<Button
							className="w-full"
							size="lg"
							isLoading={actionBtnLoading}
							onClick={onClickVerify}
						>
							<LockOpen size={16} />
							{t("credentials_verify")}
						</Button>
						<Button
							size="lg"
							onClick={onClickCancel}
							className="w-full"
							variant="outline"
						>
							{t("credentials_cancel")}
						</Button>
					</div>
				) : (
					<Button
						className="w-full"
						size="lg"
						isLoading={actionBtnLoading}
						onClick={onClickSignUp}
					>
						{t("credentials_register")}
					</Button>
				)}
			</div>
		);
	};
}
