import { fromJS } from "immutable";
import req from "../modules/request.module";
import { generateQuery } from "../components/helpers/query-helper";
import {
	INVESTORS_FETCH_COMBINED_INVESTMENT,
	INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
	CAP_TABLE_RESET_REDUCER,
} from "./types";

import { modifyTransactionIfDirty } from "../components/helpers/my-records-actions-helpers";
import { addErrorNotification, addInfoNotification } from "./notify.actions";
import history from "../interfaces/history";
import { makeUrlRelativeToCompany } from "../components/helpers/link.helper";

export function fetchMyPortfolioInvestment({
	investmentId,
	isCompany,
	viaInvestmentId,
	callback,
}) {
	const query = generateQuery({ isCompany, viaInvestmentId });

	return function (dispatch) {
		return req
			.get(`/shares/portfolio/investment/${investmentId}${query}`)
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_COMBINED_INVESTMENT,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch(() => {
				dispatch(
					addErrorNotification({ tid: "captable.error.investment_not_found" }),
				);
				history.push(makeUrlRelativeToCompany("/investments"));
			});
	};
}

export function fetchShareholderPreviewPortfolioInvestment({ investmentId }) {
	return function (dispatch) {
		return req
			.get(`/shares/shareholder-preview/portfolio/investment/${investmentId}`)
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_COMBINED_INVESTMENT,
					payload: fromJS(response.data),
				});
			});
	};
}

export function fetchPersonalInvestment(
	investmentId,
	selectedInvestmentTransactions,
	isCompany,
	callback,
) {
	const query = generateQuery({ isCompany });
	return function (dispatch) {
		return req
			.get(`/shares/personal/investment/${investmentId}${query}`)
			.then((response) => {
				let responseDataTransactions = response.data.transactions;
				if (selectedInvestmentTransactions && responseDataTransactions) {
					responseDataTransactions = modifyTransactionIfDirty(
						selectedInvestmentTransactions,
						responseDataTransactions,
					);
				}

				response.data.transactions = responseDataTransactions;

				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function deletePersonalInvestment(id, callback) {
	return async function (dispatch) {
		try {
			await req.delete(`/shares/personal/investment/${id}`);
			dispatch(
				addInfoNotification({
					tid: "investment.notification.personal_investment_deleted",
				}),
			);
			callback?.();
		} catch (error) {
			console.error(error);
			dispatch(
				addErrorNotification({
					tid: "investment.errors.faild_to_delete_personal_investment",
				}),
			);
		}
	};
}

export function resetInvestments() {
	return function (dispatch) {
		dispatch({ type: CAP_TABLE_RESET_REDUCER });
	};
}
