import React from "react";

import { GraduationCap, Lightbulb } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import useDialogModal from "@/hooks/useDialogModal";

export default function CredentialsCarouselConsulting() {
	const { t } = useTranslation();
	const { dialogElement, alert } = useDialogModal();

	return (
		<div className="flex h-full w-full select-none flex-col items-center gap-12 [&>p>strong]:text-background [&>p]:text-background/80 [&_h2]:text-xl [&_s]:opacity-40">
			<div className="flex flex-row-reverse gap-8 [&>h2>strong]:text-brand">
				<GraduationCap
					strokeWidth={0.8}
					size={70}
					className="mt-[-6px] opacity-20"
				/>
				<h2 className="flex-1">
					<Trans i18nKey={"credentials_carousel.get_started.heading"} />
				</h2>
			</div>
			<Trans i18nKey="credentials_carousel.get_started.body" />
			<Button
				variant="secondary"
				className="bg-transparent text-background hover:bg-background/20"
				size="lg"
				onClick={() =>
					alert(
						t("credentials_carousel.get_started.modal.title"),
						<div className="grid grid-cols-[60%_40%] px-20 [&_h2]:mb-6 [&_h2]:text-xl [&_h2]:font-semibold">
							<div className="[&_ul>li]:mb-4 [&_ul]:list-disc [&_ul]:px-20 [&_ul]:py-10">
								<Trans i18nKey="credentials_carousel.get_started.modal.body.what_is_covered" />
							</div>
							<div className="flex flex-col gap-12">
								<div>
									<h2>
										{t(
											"credentials_carousel.get_started.modal.body.offer_title",
										)}
									</h2>
									<div className="rounded bg-accent p-4">
										<div className="flex justify-between">
											<p>
												2 x{" "}
												{t(
													"credentials_carousel.get_started.modal.body.offer_item",
												)}{" "}
												(2 250 SEK)
											</p>
											<p>4 500 SEK</p>
										</div>
										<div className="flex justify-between">
											<p>50% {t("subscriptions.coupon.discount")}</p>
											<p>- 2 250 SEK</p>
										</div>
										<div className="flex justify-between text-lg font-bold">
											<p>{t("subscriptions.invoice.total")}</p>
											<p>= 2 250 SEK</p>
										</div>
									</div>
								</div>
								<div>
									<Trans
										i18nKey={
											"credentials_carousel.get_started.modal.body.how_to_apply"
										}
										components={[
											<Button
												key="mailto"
												variant="link"
												className="p-0"
												onClick={() => {
													window.location.href = "mailto:info@invono.se";
												}}
											/>,
										]}
									/>
								</div>
							</div>
						</div>,
						{ size: "lg" },
					)
				}
			>
				<Lightbulb /> {t("generic.learn_more")}
			</Button>
			{dialogElement}
		</div>
	);
}
