import React, { Component } from "react";
import { connect } from "react-redux";
import {
	FormattedMessage,
	FormattedHTMLMessage,
	injectIntl,
	intlShape,
} from "react-intl";
import { fromJS, Map, List } from "immutable";
import debounce from "lodash/debounce";
import ScrollView from "../../../../dumb-components/shared/layout/scroll-view/scroll-view";
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
} from "../../../../actions/transaction.actions";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import DatePicker from "../../../../dumb-components/shared/datepicker/datepicker";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import Select from "../../../../dumb-components/fields/select";
import NumericInput from "../../../../dumb-components/fields/numeric-input";
import EmissionLeveling from "./emission-leveling";
import {
	validateTransactionDate,
	isRequired,
	validateSharecapitalDecrease,
	validateNumOfRemainingSharesToRemove,
	vaidateNumOfRemovedSharesPerType,
	validateNotZero,
	validateAnySharesLeft,
} from "../../../../modules/validation.module";
import immutableForm from "../../../framework/immutable-form";
import FormButtonArea from "./formButtonArea";

const transactionType = "REDUCTION-OF-SHARE-CAPITAL";

const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {},
});

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS());
}, 1000);

class ReductionOfShareCapitalForm extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
	};

	componentDidMount = () => {
		this.props.fetchTemporaryTransaction(transactionType);
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.tmpTransaction !== prevProps.tmpTransaction) {
			this.setValidators(this.props.tmpTransaction);
		}
	};

	setValidators = (tmpTransaction) => {
		const { disableValidationOfField, enableValidationOfField } = this.props;

		if (tmpTransaction.getIn(["handlerData", "type"]) === "quota") {
			disableValidationOfField("handlerData.investments");
		} else {
			enableValidationOfField("handlerData.investments");
		}
	};

	getInvestments = () => {
		const { transaction } = this.props;

		let investments = Map();

		if (!transaction) {
			return;
		}

		const balances = transaction.get("balances", List());
		balances.map((balance, investmentId) => {
			balance.get("types", List()).forEach((shareType) => {
				let investor = investments.get(investmentId, List());

				investor = investor.push(
					Map({
						diff: 0,
						type: shareType.get("type"),
					}),
				);

				investments = investments.set(investmentId, investor);
			});
		});

		return investments;
	};

	onChange = (field, val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		const newTransaction = tmpTransaction.setIn(["handlerData", field], val);
		resetErrors("handlerData." + field);
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeType = (val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		let newTransaction = tmpTransaction.setIn(["handlerData", "type"], val);
		newTransaction = newTransaction.removeIn(["handlerData", "investments"]);

		if (
			newTransaction.getIn(["handlerData", "type"]) === "shares" &&
			newTransaction.getIn(["handlerData", "decreaseShareCapitalBy"]) > 0
		) {
			newTransaction = newTransaction.setIn(
				["handlerData", "investments"],
				this.getInvestments(),
			);
		}

		resetErrors("handlerData.type");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeDate = (val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		const newTransaction = tmpTransaction.set("date", val);
		resetErrors("date");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeShareCapital = (val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		let newTransaction = tmpTransaction.setIn(
			["handlerData", "decreaseShareCapitalBy"],
			val,
		);
		newTransaction = newTransaction.setIn(
			["handlerData", "investments"],
			this.getInvestments(),
		);
		resetErrors("handlerData.decreaseShareCapitalBy");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onSubmit = () => {
		const { tmpTransaction, createTransaction, validate } = this.props;

		if (validate(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS());
		}
	};

	cancelTransaction = () => {
		const { cancelTemporaryTransaction } = this.props;
		cancelTemporaryTransaction(transactionType);
	};

	acceptAdjustment = () => {
		const { tmpTransaction, validate, createTransaction } = this.props;

		const newTransaction = tmpTransaction.setIn(
			["handlerData", "accepted"],
			true,
		);

		if (validate(newTransaction)) {
			createTransaction(newTransaction.toJS());
		}
	};

	formatNumerInput = (num) => {
		const { formatNumber } = this.props.intl;

		if (isNaN(num)) {
			return num;
		}

		return formatNumber(num);
	};

	renderShareCapitalInput = () => {
		const { tmpTransaction, errors } = this.props;

		return (
			<Field
				name="handlerData.decreaseShareCapitalBy"
				errors={errors}
				className="form-group--mar-btm-0 col-md-12"
			>
				<div className="list__list-header">
					<div className="list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
						<div className="text--align-right">
							<FormattedMessage id="shares.transactions.reduction_of_share_capital.amount_in_sek" />
						</div>
					</div>
				</div>
				<div className="list list--striped list--table">
					<div className="list__item">
						<div className="list__item__body">
							<span className="list__item__text">
								<FormattedMessage id="shares.transactions.reduction_of_share_capital.decrease_share_capital_by" />
							</span>
						</div>
						<span className="list__item__text-area list__item__text-area--x2">
							<span className="list__item__input list__item__input--pad-right">
								<NumericInput
									className="form-control text--align-right"
									allowDecimals
									value={tmpTransaction.getIn([
										"handlerData",
										"decreaseShareCapitalBy",
									])}
									onChange={(val) => {
										this.onChangeShareCapital(val);
									}}
								/>
							</span>
						</span>
					</div>
				</div>
			</Field>
		);
	};

	render = () => {
		const { transaction, tmpTransaction, i18n, errors } = this.props;

		if (!tmpTransaction) {
			return null;
		}

		const typesOptions = [
			{
				value: "shares",
				label:
					i18n.messages[
						"shares.transactions.reduction_of_share_capital.change_number_of_shares"
					],
			},
			{
				value: "quota",
				label:
					i18n.messages[
						"shares.transactions.reduction_of_share_capital.change_quota_value"
					],
			},
		];

		const bonusIssueType = tmpTransaction.getIn(["handlerData", "type"]);

		return (
			<div className="i-content__container">
				<ScrollView autoHide={true} showOnHover={true}>
					<Panel
						tid="reduction_of_share_capital"
						className="mb-0 rounded-b-none border-b-0"
					>
						<Field
							name="handlerData.type"
							tid="shares.transactions.reduction_of_share_capital.type"
							className="col-md-7"
							errors={errors}
						>
							<Select
								value={tmpTransaction.getIn(["handlerData", "type"])}
								options={typesOptions}
								placeholder={i18n.messages["select_placeholder"]}
								simpleValue
								onSelect={(val) => {
									this.onChangeType(val);
								}}
							/>
						</Field>

						<Field
							name="date"
							tid="generic.date"
							className="col-md-5"
							errors={errors}
						>
							<DatePicker
								hasError={errors ? errors.get("date", List()).size > 0 : false}
								calendarPlacement="bottom-end"
								value={tmpTransaction.get("date")}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						{this.renderShareCapitalInput()}

						<div className="form-group--mar-btm-0 col-md-12">
							{bonusIssueType === "shares" && (
								<FormattedHTMLMessage id="shares.transactions.reduction_of_share_capital.shares.form.information" />
							)}
							{bonusIssueType === "quota" && (
								<FormattedHTMLMessage id="shares.transactions.reduction_of_share_capital.quota.form.information" />
							)}
						</div>
						{tmpTransaction.getIn(["handlerData", "type"]) === "shares" && (
							<EmissionLeveling
								latestTransaction={transaction}
								shareDiff={tmpTransaction.getIn(
									["handlerData", "investments"],
									Map(),
								)}
								increaseDiff={false}
								allowAdjustment
								onChange={(field, val) => {
									this.onChange("investments", val);
								}}
								hideEmptyShares={true}
							/>
						)}
					</Panel>

					<FormButtonArea
						onCancelClick={this.cancelTransaction}
						onSubmitClick={this.onSubmit}
					/>
				</ScrollView>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		tmpTransaction: state.transaction.getIn(
			["tmpTransaction", transactionType],
			initialTransaction,
		),
		investors: state.investors.get("list"),
		i18n: state.i18n,
	};
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
	addErrorNotification,
	addInfoNotification,
};

const validators = fromJS({
	"handlerData.type": {
		tid: "shares.transactions.reduction_of_share_capital.type",
		rules: [{ func: isRequired, message: "validation.is_required" }],
	},
	date: {
		tid: "generic.date",
		rules: [
			{
				func: isRequired,
				message: "validation.is_required",
			},
			{
				func: validateTransactionDate,
				message:
					"validation.current_transaction_date_must_be_later_then_last_transaction",
			},
		],
	},
	"handlerData.decreaseShareCapitalBy": {
		rules: [
			{ func: validateNotZero, message: "validation.share_capital_empty" },
			{
				func: validateSharecapitalDecrease,
				message: "validation.share_capital_to_decrease_lte_share_capital",
			},
			{
				func: validateAnySharesLeft,
				message: "validation.share_capital_no_left",
			},
		],
	},
	"handlerData.investments": {
		rules: [
			{
				func: validateNumOfRemainingSharesToRemove,
				message: "validation.num_of_remaining_shares_to_remove_must_be_0",
			},
			{
				func: vaidateNumOfRemovedSharesPerType,
				message: "validation.num_of_shares_to_remove_lte_num_shares_available",
			},
		],
	},
});

const ReductionOfShareCapitalFormImmutableForm = immutableForm(
	ReductionOfShareCapitalForm,
	"reductionOfShareCapitalForm",
	validators,
);
const ReductionOfShareCapitalFormInjectIntl = injectIntl(
	ReductionOfShareCapitalFormImmutableForm,
);
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(ReductionOfShareCapitalFormInjectIntl);
