import React, {
	createContext,
	createElement,
	isValidElement,
	useContext,
} from "react";
import { array, arrayOf, bool, func, node, shape, string } from "prop-types";
import withTooltip from "@/hocs/withTooltip";

import {
	Calendar,
	EllipsisVertical,
	SearchX,
	SortAsc,
	SortDesc,
} from "lucide-react";

import Moment from "@/modules/moment.module";
import { default as AvatarBase } from "@/components/avatar";
import { cn } from "@/components/utils";
import DropdownMenu from "@/components/dropdownMenu";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import Tooltip from "@/components/tooltip";
import { default as BadgesRenderer } from "@/components/badges";

const ItemListContext = createContext(null);
const ItemListRowContext = createContext(null);

export const ItemList = ({
	children,
	className,
	columns,
	columnSizing,
	rightAlignLastColumn,
	sorting,
	onSort,
	zeroStateRenderer,
}) => {
	const [sortBy, sortDesc] = sorting ?? [undefined, false];

	const zeroStateContent = children?.length > 0 ? null : zeroStateRenderer?.();

	return (
		<ItemListContext.Provider
			value={{ columnSizing, columns, rightAlignLastColumn }}
		>
			<div className={cn("flex h-full flex-col rounded-lg shadow", className)}>
				{columns && (
					<div
						style={{ gridTemplateColumns: columnSizing }}
						className="grid h-[50px] min-h-[50px] items-center justify-between gap-4 rounded-t-lg border border-b-0 border-edge bg-[rgba(255,255,255,0.4)] px-[16px] backdrop-blur-xl"
					>
						{columns.map((column, idx) => (
							<ItemListColumn
								className={cn(
									"flex font-bold",
									{
										"justify-end":
											rightAlignLastColumn && idx === columns.length - 1,
									},
									column.className,
								)}
								title={column.tooltip}
								key={column.id}
							>
								{column.isSortable ? (
									<Button
										variant="ghost"
										className={"group px-0"}
										onClick={() =>
											onSort([
												column.id,
												sortBy !== column.id ? false : !sortDesc,
											])
										}
									>
										<div
											className={cn("flex items-center gap-1", {
												"flex-row-reverse": idx === columns.length - 1,
											})}
										>
											{column.label}
											{createElement(sortDesc ? SortDesc : SortAsc, {
												size: 16,
												className: cn("opacity-0 transition-opacity", {
													"group-hover:opacity-30": sortBy !== column.id,
													"opacity-80": sortBy === column.id,
												}),
											})}
										</div>
									</Button>
								) : (
									column.label
								)}
							</ItemListColumn>
						))}
					</div>
				)}
				{(children?.length || zeroStateContent) && (
					<div
						className={cn(
							"flex h-full flex-col overflow-auto rounded-b-lg border border-edge bg-background",
							{ "rounded-t-lg": !columns },
							className,
						)}
					>
						{zeroStateContent && (
							<div className="flex h-[56px] items-center justify-center gap-2 bg-background">
								<SearchX className="!text-slate-500" size={16} />{" "}
								{zeroStateContent}
							</div>
						)}
						{children}
					</div>
				)}
			</div>
		</ItemListContext.Provider>
	);
};

ItemList.propTypes = {
	children: node,
	className: string,
	sorting: array,
	onSort: func,
	isSortable: bool,
	columns: arrayOf(
		shape({
			id: string,
			label: node,
			className: string,
		}),
	),
	zeroStateRenderer: func,
};

export const ItemListRow = ({
	children,
	isSelected,
	isChecked,
	onClick,
	className,
}) => {
	const itemListContext = useContext(ItemListContext);
	return (
		<ItemListRowContext.Provider value={{ isSelected, isChecked }}>
			<div
				role="row"
				aria-selected={isSelected}
				onClick={onClick}
				style={{ gridTemplateColumns: itemListContext.columnSizing }}
				className={cn(
					"grid items-center justify-between gap-4 border border-b-0 border-transparent border-y-slate-200 bg-background px-[16px] py-[8px] transition-colors first:border-t-0 last:rounded-b-lg",
					{
						"first:!rounded-t-lg": !itemListContext.columns,
						"cursor-pointer hover:bg-gradient-to-r hover:from-primary/10 hover:to-background hover:shadow-[white_inset_0_0_50px]":
							typeof onClick === "function",
						"bg-gradient-to-r from-primary/20 to-background shadow-[white_inset_0_0_50px]":
							isSelected || isChecked,
						"border-2 !border-y-2 border-primary border-y-primary px-[14px] py-[7px] [&+div]:border-t-0":
							isSelected,
					},
					className,
				)}
			>
				{children}
			</div>
		</ItemListRowContext.Provider>
	);
};

export const ItemListColumnBase = ({ children, className, truncate }) => {
	const itemListContext = useContext(ItemListContext);

	return (
		<div
			className={cn(
				"flex w-full justify-start",
				{
					truncate: truncate,
					"last:justify-end": itemListContext.rightAlignLastColumn,
				},
				className,
			)}
		>
			{truncate ? <div className="truncate">{children}</div> : children}
		</div>
	);
};

ItemListColumnBase.displayName = "ItemListColumnBase";

export const ItemListColumn = withTooltip()(ItemListColumnBase);

const Date = ({
	children,
	format = "L",
	className,
	displayRelativeFromNow = false,
}) => {
	return (
		<Tooltip value={Moment(children).format("llll")}>
			<div
				className={cn("inline-flex gap-2 overflow-hidden truncate", className)}
			>
				<Calendar size={16} className="text-gray-400" />
				{displayRelativeFromNow &&
				Moment().diff(Moment(children), "hours") <= 24
					? Moment(children).fromNow()
					: Moment(children).format(format)}
			</div>
		</Tooltip>
	);
};

const Avatar = ({ id, name, extra, avatarType = "user", className }) => {
	return (
		<div className={cn("flex gap-4 overflow-hidden", className)}>
			<AvatarBase id={id} name={name} type={avatarType} />
			<div className="flex flex-col justify-center overflow-hidden">
				<div className="truncate font-semibold" title={name}>
					{name}
				</div>
				{typeof extra === "string" || isValidElement(extra) ? (
					<div className="truncate text-foreground/60" title={extra}>
						{extra}
					</div>
				) : null}
			</div>
		</div>
	);
};

const Actions = ({ options }) => (
	<DropdownMenu
		trigger={
			<Button variant="ghost">
				<EllipsisVertical />
			</Button>
		}
		options={options}
	/>
);

const Badges = ({ data, maxCount = 1 }) => (
	<div>
		<BadgesRenderer data={data} maxCount={maxCount} />
	</div>
);

const Check = ({ onCheckedChange } = {}) => {
	const context = useContext(ItemListRowContext);

	return (
		<Checkbox checked={context.isChecked} onCheckedChange={onCheckedChange} />
	);
};

ItemListColumn.Date = Date;
ItemListColumn.Avatar = Avatar;
ItemListColumn.Actions = Actions;
ItemListColumn.Checkbox = Check;
ItemListColumn.Badges = Badges;
