import React, { useRef } from "react";
import { CustomTheme } from "./custom-theme";
import { ThemeProvider } from "@mui/material/styles";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";

const EmissionsBaseLayout = (props) => {
	const scrollbarRef = useRef();

	const style = `
	.base-layout__body__content {
		max-width: 100%;
	}`;

	return (
		<ThemeProvider theme={CustomTheme}>
			<style>{style}</style>
			<ScrollView
				autoHide={true}
				showOnHover={true}
				noLeftMargin={true}
				scrollbarRef={scrollbarRef}
			>
				<div className="flex h-full max-w-[1500px] flex-col p-md pl-lg">
					{props.children}
				</div>
			</ScrollView>
		</ThemeProvider>
	);
};

export default EmissionsBaseLayout;
