import React from "react";
import Moment from "../../../modules/moment.module";
import LoadingPanel from "@/components/loadingPanel";
import Cookies from "universal-cookie";
import useCanSetupSharebook from "@/hooks/shares/useCanSetUpSharebook";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import useCompanyFromUrl from "@/hooks/companies/useCompanyFromUrl";
import { Badge } from "@/components/ui/badge";
import { Trans, useTranslation } from "react-i18next";
import Panel from "@/dumb-components/shared/panel/panel";
import { Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";

const SharesSetupStartpage = () => {
	const history = useHistory();
	const canSetUpSharebook = useCanSetupSharebook();
	const { data: companyQuery } = useCompanyFromUrl();
	const company = companyQuery?.data;
	const companyUrlAlias = company?.urlAlias;

	const { t } = useTranslation();

	useEffect(() => {
		if (!companyUrlAlias) return;

		// Note: strict comparison to `false` is important
		if (canSetUpSharebook === false) {
			history.replace(`/${companyUrlAlias}`);
		}

		if (canSetUpSharebook === true) {
			const cookieValue = new Cookies().get(
				`sharesSetupCurrentOption/${companyUrlAlias}/`,
			);

			if (!["a", "b"].includes(cookieValue)) return;

			history.replace(`setup/${cookieValue}`);
		}
	}, [canSetUpSharebook, companyUrlAlias]);

	if (!canSetUpSharebook || !company) {
		return <LoadingPanel />;
	}

	const onClick = (step) => {
		new Cookies().set(`sharesSetupCurrentOption/${companyUrlAlias}/`, step, {
			path: "/",
			expires: Moment().add(1, "year").toDate(),
		});
		history.push(`setup/${step}`);
	};

	return (
		<div className="flex flex-col gap-6">
			<Panel icon={<Sparkles />} title="service.shares.setup">
				<div className="max-w-[800px] p-6">
					<Trans i18nKey="getting_started_information" />
				</div>
			</Panel>
			<div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
				<Panel title="start_share_register_from_companys_registration_date">
					<div className="relative flex h-full flex-col justify-between gap-12 p-12 pt-24">
						<Badge className="absolute left-8 top-8">
							{t("generic.recommended")}
						</Badge>
						<div>
							<Trans i18nKey="start_share_register_from_companys_registration_date_body_text" />
						</div>
						<Button onClick={() => onClick("a")}>{t("select")}</Button>
					</div>
				</Panel>

				<Panel title="start_share_register_from_a_later_date">
					<div className="flex h-full flex-col justify-between gap-12 p-12">
						<div>
							<Trans i18nKey="start_share_register_from_a_later_date_body_text" />
						</div>
						<Button variant="secondary" onClick={() => onClick("b")}>
							{t("select")}
						</Button>
					</div>
				</Panel>
			</div>
		</div>
	);
};

export default SharesSetupStartpage;
