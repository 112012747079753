import React from "react";

import Autoplay from "embla-carousel-autoplay";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { CarouselDots } from "@/components/ui/carousel";
import CredentialsCarouselConsulting from "./items/consulting";

export function CredentialsCarousel() {
	return (
		<div className="mb-[50px] mt-[118px] flex h-full w-full flex-col justify-center p-20">
			<Carousel className="h-full w-full" plugins={[Autoplay({ delay: 7000 })]}>
				<CarouselContent className="h-full">
					<CarouselItem>
						<CredentialsCarouselConsulting />
					</CarouselItem>
				</CarouselContent>
				<CarouselDots className="rounded-full p-8 [&_button]:bg-white/50 [&_button]:hover:bg-white" />
			</Carousel>
		</div>
	);
}

export default CredentialsCarousel;
