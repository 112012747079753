import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
/* import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem' */
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import Text from "../../../dumb-components/shared/text/text";
import CriteriaAdministrationModal from "./modals/CriteriaAdministrationModal";
/* import FollowersAdministrationGrid from './FollowersAdministrationGrid'
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons' */
import { fetchCompanyCriteria } from "../../../actions/dealflow.actions";
import { List, Map } from "immutable";
import { DropDown } from "../../../mui-components/dropdown/DropDown";
import IconButton from "../../../mui-components/button/icon-button";
import MenuItemIcon from "../../../mui-components/dropdown/menu/menu-item-icon";
import { openModal as openModalViaRedux } from "../../../actions/modals.actions";
import {
	DEALFLOW_ADMINISTRATION_CRITERIA_MODAL,
	CAP_TABLE_READ_MORE_MODAL,
} from "../../../constants/modals";

import { ReadMoreModal } from "../../investments/modals/read-more-modal";

export default function DealflowAdministrationOverviewContainer() {
	// const [handleModals, setHandleModals] = useState('')
	const [criteria, setCriteria] = useState(Map());
	// const [followersRowData, setFollowersRowData] = useState(null)

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const companyCriteria = useSelector(
		(state) => state.dealflow.companyCriteria,
	);
	const companySelector = useSelector((state) => state.company.company);
	// const companyFollowers = useSelector((state) => state.dealflow.companyFollowers)

	const isCriteriaAdministrationModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			DEALFLOW_ADMINISTRATION_CRITERIA_MODAL,
	);

	const isReadMoreModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) === CAP_TABLE_READ_MORE_MODAL,
	);

	const openReadMoreModal = () => {
		dispatch(openModalViaRedux(CAP_TABLE_READ_MORE_MODAL));
	};

	/* useEffect(() => {
		if (companyFollowers) {
			setFollowersRowData(companyFollowers.toJS())
		}
	}, [companyFollowers]) */

	useEffect(() => {
		dispatch(fetchCompanyCriteria(companySelector.id));
	}, [companySelector]);

	useEffect(() => {
		let companyCriteriaAndSections = Map();
		if (companyCriteria.size > 0) {
			companyCriteriaAndSections = companyCriteria;
		}
		setCriteria(companyCriteriaAndSections);
	}, [companyCriteria]);

	/* const openModal = (modal) => {
		// criteria, grid
		setHandleModals(modal)
	} */

	/* const closeModals = () => {
		setHandleModals('')
	} */

	return (
		<div className="flex h-full flex-col p-md">
			<div className="i-content__container">
				<ScrollView noLeftMargin autoHide showOnHover>
					<Grid container spacing={2} mb={2}>
						<Grid item lg={12}>
							<Card borderradius={"true"}>
								<CardContent>
									<Typography variant="body1">
										{t(
											"dealflow.administration.overview.information_card.title",
										)}
									</Typography>

									<Typography variant="body2">
										<FormattedMessage
											id={
												"dealflow.administration.overview.information_card.paragraph"
											}
											values={{
												link: (
													<Link
														sx={{ pb: 0.3 }}
														onClick={openReadMoreModal}
														component={"button"}
														color={"positive.main"}
													>
														<FormattedMessage
															id={
																"investments.information_panel.link.read_more"
															}
														/>
													</Link>
												),
											}}
										/>
									</Typography>
								</CardContent>
							</Card>
						</Grid>

						<Grid item lg={6} md={12}>
							<Card variant="solitude">
								<CardHeader
									title={
										<Text
											tid={
												"dealflow.administration.overview.criteria_card.title"
											}
										/>
									}
									action={
										criteria.size > 0 && (
											<DropDown
												alignMenu="right"
												button={({ params }) => (
													<IconButton noBorder icon="faCog" {...params} />
												)}
											>
												<MenuItemIcon
													onClick={() =>
														dispatch(
															openModalViaRedux(
																DEALFLOW_ADMINISTRATION_CRITERIA_MODAL,
															),
														)
													}
													icon={"faExpandArrowsAlt"}
													listItemTid={
														"dealflow.administration.overview.criteria_card.filter_dropdown_item.open"
													}
												/>
											</DropDown>
										)
									}
								/>
								<CardContent variant="form">
									{criteria.size > 0 ? (
										<Grid container spacing={2}>
											{List(criteria.keySeq()).map((section, index) => {
												return (
													<Grid key={index} item md={3}>
														<Typography variant={"body1"}>
															{t(`dealflow.criteria.section.${section}`)}
														</Typography>
														<Grid container>
															{criteria.get(section).map((criterion, i) => {
																return (
																	<Grid key={i} item md={12}>
																		<Typography variant={"body2"}>
																			{t(
																				`dealflow.criteria.${criterion}!${section}`,
																			)}
																		</Typography>
																	</Grid>
																);
															})}
														</Grid>
													</Grid>
												);
											})}
										</Grid>
									) : (
										<>
											<Typography variant="body2">
												{t(
													"dealflow.administration.overview.criteria_card.paragraph1",
												)}
											</Typography>
											<Typography variant="body2">
												{t(
													"dealflow.administration.overview.criteria_card.paragraph2",
												)}
											</Typography>
										</>
									)}
								</CardContent>
								{criteria.size === 0 && (
									<CardActions>
										<Button
											variant="primary"
											onClick={() =>
												dispatch(
													openModalViaRedux(
														DEALFLOW_ADMINISTRATION_CRITERIA_MODAL,
													),
												)
											}
										>
											{t(
												"dealflow.administration.overview.criteria_card.button.title",
											)}
										</Button>
									</CardActions>
								)}
							</Card>
						</Grid>

						{/* {followersRowData && (
							<Grid item lg={12}>
								<Card>
									<CardHeader
										title={<Text tid={'dealflow.administration.overview.followers_grid.title'} />}
										action={
											<GridPanelRightButtons
												openGridModal={() => openModal('grid')}
												noFilter={true}
											/>
										}
									/>
									<CardContent>
										<FollowersAdministrationGrid
											modalLeftHeader={{
												tid: 'dealflow.administration.overview.followers_grid.title'
											}}
											localCloseModal={closeModals}
											isGridModalOpen={handleModals === 'grid'}
											rowData={followersRowData}
										/>
									</CardContent>
								</Card>
							</Grid>
						)} */}
					</Grid>
				</ScrollView>
			</div>
			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={"dealflow.administration.overview.read_more.modal.title"}
					contentFile="administration-dealflow.txt"
				/>
			)}

			{isCriteriaAdministrationModalOpen && <CriteriaAdministrationModal />}
		</div>
	);
}
