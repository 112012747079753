import React from "react";
import { Filter, Mail, MailPlus, User } from "lucide-react";

import useUserComms from "@/hooks/notifications/useUserComms";
import useCreateUserComm from "@/hooks/notifications/useCreateUserComm";

import { Button } from "@/components/ui/button";
import ToolbarMenu from "@/components/toolbarMenu";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import LoadingPanel from "@/components/loadingPanel";
import { ItemList, ItemListColumn, ItemListRow } from "@/components/itemList";
import UserCommEdit from "@/components/userComms/userCommEdit";
import { UserCommStatusBadge } from "./userCommEdit";
import Breadcrumbs from "../breadcrumbs";
import i18n from "@/i18n";
import { Route, Switch } from "@/interfaces/router";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import history from "@/interfaces/history";
import { cn } from "../utils";

const { t } = i18n;

const UserCommsList = ({ highlightId, onSetFilter }) => {
	const match = useRouteMatch();
	const companyId = useCompanyIdFromUrl();

	const { data: userCommsQuery } = useUserComms();
	const userComms = userCommsQuery?.data;

	const { mutateAsync: createUserComm, isPending: isCreatingUserComm } =
		useCreateUserComm(companyId);

	const onClickWriteNew = async () => {
		const response = await createUserComm({});
		history.push(`${match.url}/${response.data.id}`);
	};

	if (!companyId) {
		return <LoadingPanel />;
	}

	return (
		<div className="flex h-full flex-col gap-4">
			<div className="flex items-center justify-between">
				<Breadcrumbs
					items={[
						{
							label: (
								<div className="flex items-center gap-2">
									<Mail /> {t("user_comms.title")}
								</div>
							),
						},
					]}
				/>
				<ToolbarMenu>
					<Button isLoading={isCreatingUserComm} onClick={onClickWriteNew}>
						<MailPlus /> {t("user_comms.actions.write_new_user_comm.title")}
					</Button>
				</ToolbarMenu>
			</div>
			<div className="max-h-full overflow-hidden">
				<ItemList
					columns={[
						{ id: "subject", label: t("subject") },
						{ id: "recipients", label: t("user_comms.recipients") },
						{ id: "createdAt", label: t("generic.created") },
						{
							id: "status",
							label: t("generic.status"),
							className: "justify-center",
						},
						{ id: "actions", label: "" },
					]}
					columnSizing="1fr 90px 160px 60px 40px"
					rightAlignLastColumn
					zeroStateRenderer={() => "You have no user communications"}
				>
					{userComms?.map((userComm) => (
						<ItemListRow
							isSelected={
								userComm.id === highlightId ||
								window.location.pathname.endsWith(userComm.id)
							}
							key={userComm.id}
							onClick={() => {
								history.push(`${match.url}/${userComm.id}`);
							}}
						>
							<ItemListColumn truncate>{userComm.subject}</ItemListColumn>
							<ItemListColumn className="flex items-center gap-2">
								<User size={16} />
								{userComm.recipients?.length ?? 0}
							</ItemListColumn>
							<ItemListColumn.Date displayRelativeFromNow>
								{userComm.createdAt}
							</ItemListColumn.Date>
							<ItemListColumn className="flex items-center justify-center gap-2">
								<UserCommStatusBadge status={userComm?.status} size="sm" />
							</ItemListColumn>
							<ItemListColumn>
								<Button
									title={t("user_comms.actions.filter_by_this.title")}
									className={cn("text-foreground/50", {
										"text-primary": userComm.id === highlightId,
									})}
									variant="ghost"
									size="sm"
									onClick={(evt) => {
										evt.stopPropagation();
										onSetFilter?.(
											userComm.id === highlightId ? null : userComm.id,
										);
									}}
								>
									<Filter size={22} />
								</Button>
							</ItemListColumn>
						</ItemListRow>
					))}
				</ItemList>
			</div>
			<Switch>
				<Route
					path={`${match.path}/:userCommId`}
					render={({
						match: {
							params: { userCommId },
						},
					}) => (
						<UserCommEdit
							id={userCommId}
							onSetFilter={onSetFilter}
							onCancel={() => history.push(match.url)}
							onChangeSelectedUserComm={(newUserCommId) =>
								history.push(`${match.url}/${newUserCommId}`)
							}
						/>
					)}
				/>
			</Switch>
		</div>
	);
};

export default UserCommsList;
