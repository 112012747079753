import React from "react";
import { bool, node } from "prop-types";
import { cn } from "./utils";

const Separator = ({ children, size, hideLeft, hideRight }) => (
	<div
		className={cn("flex w-full items-center gap-2", {
			"my-6": size === "default",
			"my-4": size === "small",
		})}
	>
		<div
			className={cn("h-[1px] flex-1 border-t", { invisible: hideLeft })}
		></div>
		<div className="flex items-center gap-1 text-input">{children}</div>
		<div
			className={cn("h-[1px] flex-1 border-t", { invisible: hideRight })}
		></div>
	</div>
);

Separator.propTypes = {
	children: node,
	hideLeft: bool,
	hideRight: bool,
};

Separator.defaultProps = {
	size: "default",
	hideLeft: false,
	hideRight: false,
};

export default Separator;
