import React, { useState } from "react";

import BillingDetailsModal from "@/components/subscriptions/billingDetailsModal";
import LoadingPanel from "@/components/loadingPanel";
import { Button } from "@/components/ui/button";

import { useTranslation } from "react-i18next";
import useSubscription from "@/hooks/subscriptions/useSubscription";
import PaymentMethods from "./paymentMethods";
import { Pen } from "lucide-react";

const BillingDetails = () => {
	const { t } = useTranslation();

	const [isEditBillingDetailsOpen, setIsEditBillingDetailsOpen] =
		useState(false);

	const { data: subscriptionRequest, isLoading: isLoadingSubscription } =
		useSubscription();
	const subscription = subscriptionRequest?.data;
	const name = subscription?.stripeData?.name;
	const address = subscription?.stripeData?.address;

	if (isLoadingSubscription) {
		return <LoadingPanel />;
	}

	return (
		<>
			<div className="flex flex-col gap-4">
				<div className="flex items-start justify-between">
					<div className="flex-1 p-4">
						{address ? (
							<div className="grid grid-cols-[auto,1fr] gap-x-6 gap-y-4">
								<span>{t("generic.name")}</span>
								<strong>{name}</strong>
								<span>{t("generic.address.line.1")}:</span>
								<strong>{address.line1}</strong>
								{address.line2 && (
									<>
										<strong></strong>
										<strong>{address.line2}</strong>
									</>
								)}
								<span>{t("generic.city")}:</span>
								<strong>{address.city}</strong>
								{address.state && (
									<>
										<span>{t("state")}:</span>
										<strong>{address.state}</strong>
									</>
								)}
								<span>{t("zip")}:</span>
								<strong>{address.postal_code}</strong>
								<span>{t("subscriptions.customer_address.country")}:</span>
								<strong>{address.country}</strong>
							</div>
						) : (
							t("subscriptions.billing_details.zero_state")
						)}
					</div>
					<Button
						className="rounded-full"
						variant="outline"
						onClick={() => setIsEditBillingDetailsOpen(true)}
						title={t("subscriptions.billing_details.edit")}
					>
						<Pen size={16} />
					</Button>
				</div>
				<PaymentMethods />
			</div>
			{isEditBillingDetailsOpen && (
				<BillingDetailsModal
					open
					onClose={() => setIsEditBillingDetailsOpen(false)}
				/>
			)}
		</>
	);
};

export default BillingDetails;
