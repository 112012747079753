import React from "react";
import { TiptapEditorToolbar } from "@/components/tiptap-editor/toolbar";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Placeholder } from "./tiptap-editor/extensions/placeholder";
import { Trans } from "react-i18next";

const EmailEditor = ({ readOnly, onCreate, onUpdate, initialContent }) => {
	return (
		<div className="flex h-full max-h-[570px] flex-col rounded-lg border border-edge shadow">
			<EditorProvider
				editable={!readOnly}
				onCreate={onCreate}
				onUpdate={onUpdate}
				extensions={[
					StarterKit,
					Underline,
					Placeholder.configure({ readOnly }),
				]}
				content={initialContent}
				slotBefore={<TiptapEditorToolbar readOnly={readOnly} />}
				editorContainerProps={{ className: "overflow-auto flex-1" }}
			/>
			<div className="text flex justify-center border-t border-slate-200 p-4 text-sm">
				<div className="flex items-center gap-2 [&_pre]:p-1">
					<Trans i18nKey="email_editor.hint_text" />
				</div>
			</div>
		</div>
	);
};

export default EmailEditor;
