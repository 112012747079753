import React from "react";
import UserCommsList from "./userCommsList";
import { Switch, Route } from "@/interfaces/router";

import SendEmailJobsList from "../sendEmailJobs/sendEmailJobsList";
import useQueryParams from "../../hooks/useQueryParams";

const UserCommsRoute = ({ match }) => {
	const [getQueryParam, setQueryParam] = useQueryParams();
	const filterByUserCommId = getQueryParam("filterBy");

	return (
		<div className="flex h-[calc(100vh-40px)] flex-col p-6">
			<Switch>
				<Route
					path={match.path}
					render={() => (
						<div className="grid h-full grid-cols-2 grid-rows-[max(100%)] items-start gap-6">
							<UserCommsList
								highlightId={filterByUserCommId}
								onSetFilter={(id) => setQueryParam("filterBy", id)}
							/>
							<SendEmailJobsList
								filterByUserCommId={filterByUserCommId}
								onClearFilter={() => setQueryParam("filterBy", null)}
							/>
						</div>
					)}
				/>
			</Switch>
		</div>
	);
};

export default UserCommsRoute;
