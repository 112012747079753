import React, { useCallback, useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "@/components/ui/input-otp";
import { bool, func, number, string } from "prop-types";
import { cn } from "@/components/utils";

const VerificationCodeInput = ({
	value,
	onChange,
	email,
	disabled,
	onComplete,
	length,
	className,
}) => {
	const ref = useRef(null);

	const applyInputFocus = useCallback(() => {
		if (disabled) return;

		onChange("");
		setTimeout(() => {
			ref?.current?.focus?.();
		});
	}, [disabled, onChange]);

	useEffect(() => {
		applyInputFocus();
		window.addEventListener("focus", applyInputFocus);
		return () => {
			window.removeEventListener("focus", applyInputFocus);
		};
	}, [disabled]);

	return (
		<div
			className={cn(
				className,
				"flex flex-col items-center gap-4 rounded-lg border p-6",
			)}
		>
			{email && (
				<div className="inline items-center gap-2 text-center">
					<Trans
						i18nKey="credentials.verify.code.code_sent_by_email_If_exist"
						values={{ email }}
					/>
				</div>
			)}
			<InputOTP
				value={value}
				maxLength={length}
				ref={ref}
				onChange={onChange}
				disabled={disabled}
				onComplete={onComplete}
			>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
					<InputOTPSlot index={3} />
				</InputOTPGroup>
			</InputOTP>
		</div>
	);
};

VerificationCodeInput.propTypes = {
	value: string,
	onChange: func,
	email: string,
	disabled: bool,
	onComplete: func,
	length: number,
	className: string,
};

VerificationCodeInput.defaultProps = {
	length: 4,
};

export default VerificationCodeInput;
