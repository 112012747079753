import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogModal from "@/components/dialogModal";
import Alert from "@/components/alert";
import PaymentMethods from "./paymentMethods";
import BillingInvoiceReceiverDetails from "@/components/subscriptions/billingInvoiceReceiverDetails";
import { Trans, useTranslation } from "react-i18next";
import useDefaultPaymentMethod from "@/hooks/subscriptions/useDefaultPaymentMethod";
import { Calendar, CreditCard } from "lucide-react";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import LoadingPanel from "../loadingPanel";
import useSubscription from "@/hooks/subscriptions/useSubscription";
import useChangeSubscriptionPlan from "@/hooks/subscriptions/useChangeSubscriptionPlan";
import { SUBSCRIPTION_ESIGN_BANKID_COST } from "/shared/constants";
import SubscriptionAccountPlanCard from "@/components/subscriptions/subscriptionAccountPlanCard";
import useSubscriptionPlans from "@/hooks/subscriptions/useSubscriptionPlans";
import useDialogModal from "@/hooks/useDialogModal";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import CouponField from "./couponField";
import useValidation from "@/hooks/useValidation";
import { withRouter } from "../../interfaces/router";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";

const SubscriptionCheckoutModal = ({
	onCancel,
	confirmationMessageRenderer,
	selectedAccountPlanId,
	onPurchaseComplete,
	onChangeSelectedAccountPlanId,
	...props
}) => {
	const { t } = useTranslation();
	const companyId = useCompanyIdFromUrl();
	const { alert, dialogElement } = useDialogModal();

	const {
		isLoading: isLoadingDefaultPaymentMethod,
		data: defaultPaymentMethod,
	} = useDefaultPaymentMethod();
	const { data: subscriptionQuery } = useSubscription();
	const subscription = subscriptionQuery?.data;

	const isInvoiced =
		subscriptionQuery?.data?.stripeData?.metadata?.isInvoiced === "true";

	const {
		mutate: updateSubscriptionPlan,
		isPending: isUpdatingSubscriptionPlan,
	} = useChangeSubscriptionPlan(companyId);

	const { data: subscriptionPlansQuery } = useSubscriptionPlans();
	const subscriptionPlans = subscriptionPlansQuery?.data;

	const selectedAccountPlan = [
		...subscriptionPlans.account.month,
		...subscriptionPlans.account.year,
	].find((p) => p.id === selectedAccountPlanId);

	const confirmationMessage = confirmationMessageRenderer?.();

	const coupon = subscription?.stripeData?.discount?.coupon;

	const basePrice = selectedAccountPlan.amount / 100;
	let discountedPrice = basePrice;

	if (typeof coupon?.amount_off === "number")
		discountedPrice -= coupon.amount_off / 100;
	else if (typeof coupon?.percent_off === "number")
		discountedPrice -= (coupon.percent_off / 100) * basePrice;

	const discountedDifference = basePrice - discountedPrice;
	const addedTax = discountedPrice * 0.25;
	const taxedTotalPrice = discountedPrice + addedTax;

	const isMonthly = selectedAccountPlan.interval === "month";

	const [showValidation, setShowValidation] = useState(false);
	const { validate, validationMessages } = useValidation({
		paymentMethods: (assert) => {
			if (!isInvoiced) {
				assert(
					!!defaultPaymentMethod,
					t("subscriptions.payment_methods.payment_card_required.title"),
				);
			}
		},
	});

	useEffect(() => {
		showValidation && validate();
	}, [defaultPaymentMethod, isInvoiced]);

	const onChangePlan = async () => {
		if (!validate()) return setShowValidation(true);

		const { prepaidAmount } = props;
		const previousAccountPlanId = subscription.accountPlanId;

		// If user upgraded from FREE account and don't have any money in the pot,show pot info modal
		const showPotInfo =
			previousAccountPlanId === "freeAcount" && prepaidAmount <= 0;

		updateSubscriptionPlan(
			{ planId: selectedAccountPlanId },
			{
				onSuccess: async () => {
					showPotInfo &&
						(await alert(
							t("subscriptions.balance_info.modal.title"),
							<Trans
								i18nKey="subscriptions.balance_info.modal.body"
								values={{
									singleSignaturePrice: `${SUBSCRIPTION_ESIGN_BANKID_COST} SEK`,
								}}
							/>,
							{ variant: "warning" },
						));

					onPurchaseComplete?.();
				},
			},
		);
	};

	const onSwitchPlanInterval = () => {
		const newInterval = isMonthly ? "year" : "month";
		const newPlan = subscriptionPlans.account[newInterval].find(
			(p) => p.metadata.plan === selectedAccountPlan.metadata.plan,
		);

		onChangeSelectedAccountPlanId(newPlan.id);
	};

	const isLoading =
		!subscriptionPlans ||
		!companyId ||
		!subscription ||
		isLoadingDefaultPaymentMethod;

	return (
		<>
			<DialogModal
				size="lg"
				title={t("subscriptions.purchase.change_subscription")}
				submitText={
					<>
						<CreditCard size={16} />
						{t("subscriptions.checkout.confirm_payment")}
					</>
				}
				onCancel={onCancel}
				onSubmit={onChangePlan}
				isSubmitting={isUpdatingSubscriptionPlan}
				bodyRenderer={() =>
					isLoading ? (
						<LoadingPanel />
					) : (
						<div className="grid grid-cols-2 gap-8">
							<div className="flex flex-col gap-8">
								<h2 className="text-lg font-bold">
									{t("subscriptions.payment_methods.singular")}
								</h2>
								<PaymentMethods
									validationMessage={validationMessages.paymentMethods}
								/>
								<BillingInvoiceReceiverDetails />
							</div>
							<div className="flex flex-col gap-8">
								<h2 className="text-lg font-bold">{t("generic.summary")}</h2>
								{!!confirmationMessage?.length && (
									<Alert
										icon={<CreditCard />}
										title={t("generic.please_note")}
										message={confirmationMessage}
									/>
								)}
								<SubscriptionAccountPlanCard plan={selectedAccountPlan} />
								<CouponField />
								<div className="flex flex-col rounded-xl bg-slate-100 p-6">
									<div className="flex justify-between">
										<div>
											{t(
												`subscriptions.plans.company-account.${selectedAccountPlan.metadata.plan}`,
											)}
											{" - "}
											{isMonthly
												? t("subscriptions.plans.monthly")
												: t("subscriptions.plans.yearly")}
										</div>
										<div>{localeFormatNumber(basePrice, "currency")}</div>
									</div>
									{discountedPrice !== basePrice && (
										<div className="flex justify-between">
											<div>
												{t("subscriptions.subscribed_product.total.discount")}{" "}
												{typeof coupon?.amount_off === "number"
													? `(-${localeFormatNumber(
															coupon.amount_off / 100,
															"currency",
													  )})`
													: `(-${coupon.percent_off}%)`}
											</div>
											<div>
												-{localeFormatNumber(discountedDifference, "currency")}
											</div>
										</div>
									)}
									<div className="flex justify-between">
										{t("subscriptions.summary.vat", {
											percentage: 25,
											amount: localeFormatNumber(discountedPrice, "currency"),
										})}
										<div>{localeFormatNumber(addedTax, "currency")}</div>
									</div>

									<div className="mt-4 flex justify-between border-t-[1px] border-t-slate-300">
										<div>
											<div className="pt-2 text-lg font-bold">
												{isMonthly
													? t("subscriptions.subscribed_product.total.month")
													: t("subscriptions.subscribed_product.total.year")}
											</div>
											<div className="flex gap-4">
												<Button variant="link" onClick={onSwitchPlanInterval}>
													<Calendar size={16} />
													{isMonthly
														? t("subscriptions.switch_to_yearly")
														: t("subscriptions.switch_to_monthly")}
												</Button>
												{isMonthly && (
													<Badge size="sm" variant="outline-orange">
														{t("subscriptions.plans.switch_and_save_discount", {
															discount: "10%",
														})}
													</Badge>
												)}
											</div>
										</div>
										<div className="pt-2 text-lg font-bold">
											{localeFormatNumber(taxedTotalPrice, "currency")}
										</div>
									</div>
								</div>
								{!isInvoiced && (
									<Alert
										variant="ghost"
										icon={<CreditCard />}
										title={t("generic.please_note")}
										message={t("subscriptions.confirm_purchase.question")}
									/>
								)}
							</div>
						</div>
					)
				}
				{...props}
			/>
			{dialogElement}
		</>
	);
};

SubscriptionCheckoutModal.propTypes = {
	selectedAccountPlanId: PropTypes.string.isRequired,
	onCancel: DialogModal.propTypes.onCancel,
	confirmationMessageRenderer: PropTypes.func,
	onPurchaseComplete: PropTypes.func,
};

export default withRouter(SubscriptionCheckoutModal);
