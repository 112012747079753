import React from "react";
import { ChevronRight } from "lucide-react";
import { arrayOf, func, node, shape } from "prop-types";
import { cn } from "@/components/utils";

const Breadcrumbs = ({ items }) => {
	if (!items) return null;

	return (
		<nav aria-label="breadcrumb" className="flex items-center gap-2 text-xl">
			{items.filter(Boolean).map((item, idx) => {
				const className = cn({
					"font-semibold": items.length > 1 && idx === items.length - 1,
					"hover:underline cursor-pointer": !!item.onClick,
				});

				return (
					<React.Fragment key={item.label}>
						{idx !== 0 && <ChevronRight size={16} />}
						<div
							role={item.onClick ? "button" : undefined}
							className={className}
							onClick={item.onClick}
						>
							{item.label}
						</div>
					</React.Fragment>
				);
			})}
		</nav>
	);
};

Breadcrumbs.propTypes = {
	items: arrayOf(
		shape({
			label: node.isRequired,
			onClick: func,
		}).isRequired,
	),
};

export default Breadcrumbs;
