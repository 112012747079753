import React, { cloneElement } from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

const Badges = ({ data, maxCount }) => {
	const { t } = useTranslation();

	if (!data || !data.length) return null;

	const allBadges = data.map((badge) => (
		<Badge
			key={badge.id}
			size="sm"
			className="mb-1 mr-1"
			variant={badge.variant}
		>
			{badge.name}
		</Badge>
	));

	const exceedsLimit =
		typeof maxCount === "number" && maxCount < allBadges.length;

	return (
		<div>
			<div className="flex truncate">
				{exceedsLimit ? allBadges.slice(0, maxCount) : allBadges}
			</div>
			{exceedsLimit && (
				<Button
					onClick={(evt) => {
						evt.preventDefault();
						evt.stopPropagation();
					}}
					className="h-[16px] p-0"
					variant="link"
					title={
						<div className="flex max-w-[300px] flex-wrap justify-center gap-2">
							{allBadges.map((b) =>
								cloneElement(b, {
									size: "default",
									variant: "outline-background",
								}),
							)}
						</div>
					}
				>
					{t("generic.plus_x_more", { count: allBadges.length - maxCount })}
				</Button>
			)}
		</div>
	);
};

export default Badges;
