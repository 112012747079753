import React, { PureComponent } from "react";
import { Button } from "@/components/ui/button";
import { bool, func, oneOf, string } from "prop-types";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { map } from "react-immutable-proptypes";
import { Map } from "immutable";
import i18n from "@/i18n";

const { t } = i18n;

import {
	EMAIL_FIELD,
	TOS_FIELD,
	CODE_FIELD,
	PHONE_FIELD,
} from "../../../constants/credentials";
import { Trans } from "react-i18next";
import VerificationCodeInput from "./verificationCodeInput";
import { LockOpen } from "lucide-react";

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickVerify: func,
		onClickCancel: func,
		onClickSignUp: func,
		bankIdState: oneOf(["pending", "failed"]),
		mode: oneOf(["signup", "verify"]),
		email: string,
		verifyCode: string,
		onChange: func,
		tos: bool,
		hasError: map,
		isVerifyMode: bool,
		actionBtnLoading: bool,
		onOpenTos: func,
		referredBySignin: bool,
	};

	static defaultProps = {
		tos: false,
		mode: "signup",
		hasError: Map(),
	};

	renderRefInfoText = () => {
		const { referredBySignin } = this.props;

		if (!referredBySignin) {
			return null;
		}

		return t("credentials.signup.refered.info");
	};

	renderEmailInput = () => {
		const {
			hasError,
			onClickSignUp,
			actionBtnLoading,
			isVerifyMode,
			onChange,
			email,
		} = this.props;

		return (
			<div>
				<label htmlFor="email">{t("credentials.signup.label.email")}</label>
				<Input
					autoFocus
					id="email"
					value={email}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignUp()}
					disabled={isVerifyMode || actionBtnLoading}
					onChange={(evt) => onChange(EMAIL_FIELD, evt.target.value)}
					validationMessage={
						hasError.get(EMAIL_FIELD)?.size > 0 &&
						hasError.get(EMAIL_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderPhoneInput = () => {
		const {
			onClickSignUp,
			hasError,
			onChange,
			phone,
			isVerifyMode,
			actionBtnLoading,
		} = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<div>
				<label htmlFor="phone">{t("credentials.verify.phone.label")}</label>
				<Input
					id="phone"
					value={phone}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignUp()}
					disabled={isVerifyMode || actionBtnLoading}
					onChange={(evt) => onChange(PHONE_FIELD, evt.target.value)}
					validationMessage={
						hasError.get(PHONE_FIELD)?.size > 0 &&
						hasError.get(PHONE_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderTosSwitch = () => {
		const {
			hasError,
			isVerifyMode,
			onChange,
			tos,
			actionBtnLoading,
			onOpenTos,
		} = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<div className="flex items-center gap-3">
				<Checkbox
					id="accept-tos"
					checked={tos}
					onCheckedChange={(checked) => onChange(TOS_FIELD, checked)}
					disabled={actionBtnLoading}
					validationMessage={
						hasError.get(TOS_FIELD)?.size > 0 &&
						hasError.get(TOS_FIELD).map(t).toJS()
					}
					label={
						<Trans
							i18nKey="crendentials.accept_tos.label"
							components={[
								<Button
									className="p-0"
									key="tos_link"
									variant="link"
									onClick={onOpenTos}
								/>,
							]}
						/>
					}
				/>
			</div>
		);
	};

	renderVerifyCodeInput = () => {
		const {
			onClickVerify,
			actionBtnLoading,
			isVerifyMode,
			onChange,
			code,
			email,
		} = this.props;

		if (!isVerifyMode) {
			return null;
		}

		return (
			<VerificationCodeInput
				email={email}
				value={code}
				onChange={(code) => {
					onChange(CODE_FIELD, code);
				}}
				onComplete={onClickVerify}
				disabled={actionBtnLoading}
			/>
		);
	};

	render = () => {
		const {
			onClickSignUp,
			isVerifyMode,
			onClickVerify,
			onClickCancel,
			actionBtnLoading,
		} = this.props;

		return (
			<div className="flex flex-col gap-6">
				{this.renderRefInfoText()}
				{this.renderEmailInput()}
				{this.renderPhoneInput()}
				{this.renderTosSwitch()}
				{this.renderVerifyCodeInput()}

				{isVerifyMode ? (
					<div className="flex justify-stretch gap-2">
						<Button
							className="w-full"
							size="lg"
							onClick={onClickVerify}
							isLoading={actionBtnLoading}
						>
							<LockOpen size={16} />
							{t("credentials_verify")}
						</Button>
						<Button
							className="w-full"
							size="lg"
							onClick={onClickCancel}
							variant="outline"
						>
							{t("credentials_cancel")}
						</Button>
					</div>
				) : (
					<Button
						className="w-full"
						size="lg"
						onClick={onClickSignUp}
						isLoading={actionBtnLoading}
					>
						{t("auth.signup_with_bankid")}
					</Button>
				)}
			</div>
		);
	};
}
