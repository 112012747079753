import React, { Component } from "react";
import { connect } from "react-redux";
import NavTabs from "../../menu/nav-tabs";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";

import Breadcrumbs from "@/components/breadcrumbs";
import { Margin, Padding } from "styled-components-spacing";
import Box from "../../../dumb-components/shared/layout/box/box";
import UserManagementSettingsContainer from "../../../containers/user-management/user-management-settings,container";
import i18n from "../../../i18n";

const { t } = i18n;

class UserManagementContainer extends Component {
	render() {
		const { company, roleSelectorComponent, children, location } = this.props;
		const tabItems = [
			{
				url: "/" + company.urlAlias + "/user-management",
				translationId: "generic.users",
			},
			{
				url: "/" + company.urlAlias + "/user-management/roles",
				translationId: "user_management.teams",
			},
			{
				url: "/" + company.urlAlias + "/user-management/access",
				translationId: "generic.access",
			},
		];

		const currentTab = tabItems.find((item) => item.url === location.pathname);
		const currentTabTid = currentTab && currentTab.translationId;

		const breadcrumbsItems = [
			{
				label: t("user_management.breadcrumbs.title"),
			},
			currentTabTid && {
				label: t(currentTabTid),
			},
		];

		return (
			<div className="flex h-full flex-col p-md">
				<Margin bottom={4}>
					<Box>
						<Breadcrumbs items={breadcrumbsItems} />
						{/* <Breadcrumbs>
							<BreadcrumbsItem
								itemName={<Text tid="user_management.breadcrumbs.title" />}
								isStatic={true}
							/>
							{currentTabTid && (
								<BreadcrumbsItem
									itemName={<Text tid={currentTabTid} />}
									isStatic={true}
								/>
							)}
						</Breadcrumbs> */}

						<UserManagementSettingsContainer />
					</Box>
				</Margin>

				<div className="i-content__body">
					<div className="i-content__container">
						<div className="i-content__tabs i-content__tabs--left i-content__tabs--padding-btm-0 i-content__tabs--padding-left-0 i-content__tabs--padding-right-0">
							<div className="f1-box">
								<NavTabs items={tabItems} onTabChanged={this.onTabChanged} />
							</div>
							<Padding bottom={2} right={3}>
								{roleSelectorComponent && roleSelectorComponent()}
							</Padding>
						</div>
						<ScrollView noLeftMargin={true} autoHide={true} showOnHover={true}>
							{children}
						</ScrollView>
					</div>
				</div>
			</div>
		);
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return { company: state.company.company };
}

export default connect(mapStateToProps)(UserManagementContainer);
