import React, { Component, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { oneOf, bool, func } from "prop-types";
import { fromJS, List } from "immutable";
import { required } from "../../../../modules/field-validators";
import { Padding } from "styled-components-spacing";

//Form dep
import { Field, reduxForm, getFormValues } from "redux-form";
import {
	renderField,
	renderReactSelectField,
	renderIdField,
	renderNameField,
	renderInvestorEmailField,
} from "../../../helpers/redux-form--fields";
import FlexTable from "../../../framework/flex-table";
import { numberFormatInputFieldFormatter } from "../../../framework/flex-table/formatters";
import { FormattedMessage } from "react-intl";
import RecommendInvonoPlatformField from "../../../helpers/recommend-invono-platform-field";
import debounce from "lodash/debounce";
import { getShareTypeLabel } from "../../../helpers/shares";
import {
	fetchInvestor,
	editInvestor,
	deleteInvestor,
	archiveInvestor,
	editInvestorLocal,
	investorIsDirty,
	setSelectedInvestor,
	inviteInvestor,
	listInvestors,
} from "../../../../actions/investors.actions";
import { openModal } from "../../../../actions/modals.actions";
import { listCapitalInsurances } from "../../../../actions/capital-insurance.actions";
import { listDepos } from "../../../../actions/depos.actions";
import { isRequired } from "../../../../modules/validation.module";
import Panel from "../../../../dumb-components/shared/panel/panel";
import {
	validateEmail,
	validatePersonNumber,
	validateOrgNumber,
} from "../../../../modules/validation.module";
import ShareholdersToolbarControlsContainer from "../../../../containers/shares/shareholders-toolbar-controls.container";
import CountrySelect from "../../../../dumb-components/shared/country-select/country-select";
import InvestorIdUnlockBtnContainer from "../../../../containers/shares/investor-id-unlock-btn.container";

import AttachmentsByObjIdContainer from "../../../../containers/shared/attachments-by-objid.container";
import { renderCommonAttachmentDropdown } from "../../../../containers/documents/attachments/predefined-dropdown-render-functions";
import EditorContainer from "../../../../containers/shared/editor.container";
import RemindShareholderModalContainer from "../../../../containers/notify-by-templates/modals/shares/remind-shareholder-modal.container";
import {
	EVENT_TYPE_SHARES_INVESTMENT_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_DELETE,
	OBJ_TYPE_INVESTMENT,
	INVESTOR_TYPE_OF_OWNER_COMPANY,
} from "/shared/constants";
import { REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL } from "../../../../constants/modals";

import { withRouter } from "../../../../interfaces/router";
import investmentApi from "/shared/helpers/investment.helper";
import { InvestorsContactBook } from "./investors-contact-book/investors-contact-book.container";
import clsx from "clsx";

import i18n from "@/i18n";
import useDialogModal from "@/hooks/useDialogModal";
import withResolvedProps from "@/hocs/withResolvedProps";
import { Building2, Landmark, User } from "lucide-react";
import { Button } from "@/components/ui/button";
import DialogModal from "@/components/dialogModal";

const { t } = i18n;

// eslint-disable-next-line
class InvestorsDetails extends Component {
	state = {
		isLoading: false,
	};

	static propTypes = {
		mode: oneOf(["MODAL"]),
		isOpen: bool,
		onCloseModal: func,
		actionMode: oneOf(["CREATE", "EDIT"]),
	};

	static defaultProps = {
		mode: "MODAL",
		actionMode: "EDIT",
	};

	componentDidMount = () => {
		const {
			match: { params },
			actionMode,
			depos,
			listDepos,
			capitalInsurances,
			listCapitalInsurances,
		} = this.props;

		if (!depos || depos?.size === 0) {
			listDepos();
		}

		if (!capitalInsurances || capitalInsurances?.size === 0) {
			listCapitalInsurances();
		}
		actionMode === "EDIT" && params?.id && this.fetchInvestor(params.id);
	};

	componentDidUpdate = (prevProps) => {
		const {
			match: { params },
			actionMode,
		} = this.props;

		this.checkLiveUpdateEvents();

		if (actionMode === "EDIT" && params?.id !== prevProps.match.params?.id) {
			params.id && this.fetchInvestor(params.id);
		}
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			match: { params },
			isModal,
			history,
		} = this.props;

		if (isModal) {
			return;
		}

		const investorUpdate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_UPDATE);
		const investorDelete = audit.get(EVENT_TYPE_SHARES_INVESTMENT_DELETE);
		const basePath = this.getComponentBasePath();

		if (
			investorUpdate &&
			investorUpdate.get("refresh") === true &&
			investorUpdate.get("objId") === params.id
		) {
			this.props.fetchInvestor(params.id);
		}

		if (
			investorDelete &&
			investorDelete.get("refresh") === true &&
			investorDelete.get("objId") === params.id
		) {
			history.push(basePath);
		}
	};

	fetchInvestor = (id) => {
		this.props.fetchInvestor(id);
	};

	doDebounce = debounce(() => {
		this.props.handleSubmit(this.onSubmit)();
	}, 1000);

	debounce = () => {
		this.props.investorIsDirty();
		this.doDebounce();
	};

	onSubmit = (props) => {
		const { selectedInvestor } = this.props;
		if (selectedInvestor && selectedInvestor.get("id")) {
			this.props.editInvestor(selectedInvestor.get("id"), props);
		}
	};

	checkRequiredFields = () => {
		const { selectedInvestor } = this.props;
		const requiredFields = ["investorInformation.id"];
		let valid = true;

		requiredFields.forEach((requiredField) => {
			const fieldPath = requiredField.split(".");
			const result = isRequired(selectedInvestor.getIn(fieldPath));

			if (!result) {
				valid = false;
			}
		});

		return valid;
	};

	onChange = (func, newValue, prevValue, field) => {
		const { editInvestorLocal } = this.props;
		let { selectedInvestor } = this.props;
		const fieldPath = field.split(".");

		selectedInvestor = selectedInvestor.setIn(fieldPath, newValue);
		selectedInvestor = selectedInvestor.setIn(["metadata", "edited"], true);
		editInvestorLocal(selectedInvestor);
		this.debounce();
		this.getDeposOptions();
	};

	onChangeCapitalInsurance = (func, newValue) => {
		const { editInvestorLocal, capitalInsurances } = this.props;
		let { selectedInvestor } = this.props;
		const capitalInsuranceObj = capitalInsurances.find(
			(cap) => cap.get("organisationNumber") === newValue,
		);
		const name = capitalInsuranceObj.get("name");
		const organisationNumber = capitalInsuranceObj.get("organisationNumber");
		const address = capitalInsuranceObj.get("address");
		const city = capitalInsuranceObj.get("city");
		const zip = capitalInsuranceObj.get("zip");

		selectedInvestor = selectedInvestor.setIn(
			["investorInformation", "name"],
			name,
		);
		selectedInvestor = selectedInvestor.setIn(
			["investorInformation", "id"],
			organisationNumber,
		);
		selectedInvestor = selectedInvestor.setIn(
			["investorInformation", "address"],
			address,
		);
		selectedInvestor = selectedInvestor.setIn(
			["investorInformation", "city"],
			city,
		);
		selectedInvestor = selectedInvestor.setIn(
			["investorInformation", "zip"],
			zip,
		);
		selectedInvestor = selectedInvestor.setIn(["metadata", "edited"], true);

		editInvestorLocal(selectedInvestor);
		this.debounce();
	};

	onChangeEditor = (fieldPath, newValue) => {
		const { editInvestorLocal } = this.props;
		let { selectedInvestor } = this.props;
		fieldPath = fieldPath.split(".");

		selectedInvestor = selectedInvestor.setIn(fieldPath, newValue);
		selectedInvestor = selectedInvestor.setIn(["metadata", "edited"], true);
		editInvestorLocal(selectedInvestor);
		this.debounce();
	};

	onChangeCountry = (value) => {
		this.onChange(null, value, null, "investorInformation.country");
	};

	onChangeCountryCaptialIncurance = (value) => {
		this.onChange(
			null,
			value,
			null,
			"captialIncuranceOwnerInformation.country",
		);
	};

	onChangeLinkedDocuments = (linkedDocuments) => {
		const { selectedInvestor } = this.props;
		const updatedInvestor = selectedInvestor.set(
			"linkedDocuments",
			linkedDocuments,
		);
		this.props.editInvestor(updatedInvestor.get("id"), updatedInvestor.toJS());
	};

	inviteShareholder = (index) => {
		const { selectedInvestor, openModal } = this.props;

		openModal(REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL, {
			investor: selectedInvestor,
			index,
		});
	};

	deleteShareholder = async () => {
		const { selectedInvestor, history, confirm, deleteInvestor } = this.props;

		if (selectedInvestor === null) {
			return false;
		}

		const isDeleteConfirmed = await confirm(
			t("delete_shareholder"),
			t("shareholders.delete_shareholder.confirm_modal.info"),
			{
				variant: "destructive",
				submitText: t("delete_shareholder"),
			},
		);

		if (!isDeleteConfirmed) return;

		deleteInvestor(selectedInvestor.get("id")).then(() => {
			history.push(
				location.pathname.replace("/" + selectedInvestor.get("id"), ""),
			);
		});
	};

	closeModal = () => {
		const {
			onCloseModal,
			editInvestorLocal,
			deleteInvestor,
			selectedInvestor,
		} = this.props;

		deleteInvestor(selectedInvestor.get("id"));

		onCloseModal && onCloseModal(null, "DISMISSED");
		editInvestorLocal(null);
	};

	saveAndCloseModal = () => {
		const { onCloseModal, editInvestorLocal, listInvestors, selectedInvestor } =
			this.props;

		onCloseModal && onCloseModal(selectedInvestor, "SAVED");
		editInvestorLocal(null);
		listInvestors();
	};

	getComponentBasePath() {
		const pathNameArray = this.props.location.pathname.split("/");
		pathNameArray.pop();
		return pathNameArray.join("/");
	}

	capitalInsuranceCheckAllFields = () => {
		const { formValues } = this.props;

		if (!formValues) {
			return false;
		}

		const investorInformation = formValues.investorInformation;

		if (
			investorInformation &&
			investorInformation.depositoryInstitute &&
			investorInformation.name &&
			investorInformation.insuranceNr
		) {
			return true;
		}

		return false;
	};

	handleOnClickNewInvestor = () => {
		const basePath = this.getComponentBasePath();
		this.props.history.push(basePath + "/new");
	};

	getEmailToSelectedInvestor = () => {
		const { selectedInvestor } = this.props;

		if (!selectedInvestor) {
			return null;
		}

		// Get investor email
		const investorEmail = investmentApi.getInvestorEmail(
			selectedInvestor.toJS(),
		);

		// Check if email is valid, if not, disabled button
		const disabled = !validateEmail(investorEmail);

		const mailToInvestorData = {
			mailto: `mailto:${investorEmail}`,
			email: investorEmail,
			disabled,
		};

		return mailToInvestorData;
	};

	getCapitalInsuranceOptions = () => {
		const { capitalInsurances } = this.props;
		let options = [];

		capitalInsurances.forEach((capitalInsurance) => {
			options.push({
				value: capitalInsurance.get("organisationNumber"),
				label:
					capitalInsurance.get("name") +
					" | " +
					capitalInsurance.get("organisationNumber"),
			});
		});

		return options;
	};

	getDeposOptions = () => {
		const { depos, selectedInvestor } = this.props;
		let prefilledDepoId =
			selectedInvestor &&
			selectedInvestor.getIn(["investorInformation", "depositoryInstitute"]);
		let prefilledDepo = depos
			.filter((depo) => depo.get("id") === prefilledDepoId)
			.get(0);
		let options = [];

		//	make sure that chosen but deleted depo shows
		prefilledDepo &&
			prefilledDepo.get("isDeleted") === true &&
			options.push({
				value: prefilledDepo.get("id"),
				label: prefilledDepo.get("name"),
			});

		//	shows all other depos that are not deleted
		depos.forEach((depo) => {
			if (depo.get("isDeleted") === false) {
				options.push({
					value: depo.get("id"),
					label: depo.get("name"),
				});
			}
		});

		return options;
	};

	renderContactBook = ({ input, meta: { touched, error } }) => {
		const { selectedInvestor } = this.props;

		const hasInvestorId = Boolean(
			selectedInvestor && selectedInvestor.get("investorId"),
		);

		return (
			<div
				className={clsx("form-group", "col-md-12", {
					"has-error": touched && error,
				})}
			>
				<InvestorsContactBook
					onChange={input.onChange}
					values={input.value || []}
					isRegisteredCompany={hasInvestorId}
				/>
			</div>
		);
	};

	renderButtons = () => {
		const { selectedInvestor } = this.props;

		if (!selectedInvestor) {
			return null;
		}

		const isDeletable = selectedInvestor.get("isDeletable") ? true : false;
		const hasInvestorId = selectedInvestor.get("investorId") ? true : false;
		const selectedInvestmentId = selectedInvestor.get("id");

		return (
			<div className="flex min-h-[40px] justify-end">
				<ShareholdersToolbarControlsContainer
					selectedInvestmentId={selectedInvestmentId}
					isLinked={hasInvestorId}
					onDeleteShareholder={this.deleteShareholder}
					deleteShareholderDisabled={!isDeletable}
				/>
			</div>
		);
	};

	renderShares = () => {
		const { formValues, selectedInvestor, panelType, panelMode, isModal } =
			this.props;

		if (!formValues?.investorTypeOfOwner) return null;

		const types = selectedInvestor
			.getIn(["details", "types"], List())
			.filter((type) => {
				return type.get("shares") > 0;
			});

		const columns = fromJS([
			{
				prop: "type",
				size: "6",
				formatter: getShareTypeLabel,
				header: { translationId: "shares.class_of_shares" },
			},
			{
				prop: "shares",
				size: "3",
				header: {
					translationId: "shares_amount",
					className: "text--align-right",
				},
				formatter: numberFormatInputFieldFormatter,
				readOnly: true,
				inputClassName: "text--align-right",
			},
			{
				prop: "latestPrice",
				size: "3",
				header: {
					translationId: "value_per_share",
					className: "text--align-right",
				},
				formatter: numberFormatInputFieldFormatter,
				readOnly: true,
				decimalScale: 4,
				inputClassName: "text--align-right",
			},
		]);

		return (
			<Panel
				title="shares"
				type={panelType}
				mode={panelMode}
				marginBottom={!isModal}
			>
				<Padding all={4} bottom={0}>
					<FlexTable columns={columns} data={types} />
					{!types && (
						<div>
							<p>
								<span className="text--headline">
									<FormattedMessage id="no_transactions_for_this_shareholder" />
								</span>
								<br />
								<FormattedMessage id="no_transactions_for_this_shareholder_info" />
							</p>
							<p>
								<FormattedMessage id="click_on_transaction_menu_item_to_select_option" />
							</p>
						</div>
					)}
				</Padding>
			</Panel>
		);
	};

	renderManageIdToggle = () => {
		return <InvestorIdUnlockBtnContainer />;
	};

	renderManageIdToggleCapital = () => {
		return <InvestorIdUnlockBtnContainer isCapitalIncurance={true} />;
	};

	renderInvestor = () => {
		const {
			selectedInvestor,
			formValues,
			i18n,
			isModal,
			panelType,
			panelMode,
		} = this.props;
		const hasInvestorId =
			selectedInvestor && selectedInvestor.get("investorId") ? true : false;
		const id =
			selectedInvestor && selectedInvestor.getIn(["investorInformation", "id"]);
		const capitalInsuranceId =
			selectedInvestor &&
			selectedInvestor.getIn(["captialIncuranceOwnerInformation", "id"]);

		const hasSwedishId = validatePersonNumber(id) || validateOrgNumber(id);
		const hasSwedishIdCapital =
			validatePersonNumber(capitalInsuranceId) ||
			validateOrgNumber(capitalInsuranceId);
		const addressFromProfile =
			(selectedInvestor &&
				investmentApi.getInvestorProperty(
					selectedInvestor.toJS(),
					"addressFromProfile",
				)) ||
			false;
		const phoneFromProfile =
			(selectedInvestor &&
				investmentApi.getInvestorProperty(
					selectedInvestor.toJS(),
					"phoneFromProfile",
				)) ||
			false;
		const isActiveUser =
			selectedInvestor && selectedInvestor.getIn(["computed", "isActiveUser"]);
		const isActiveCompany =
			selectedInvestor &&
			selectedInvestor.getIn(["computed", "isActiveCompany"]);
		const investorTypeOfOwner =
			selectedInvestor && selectedInvestor.get("investorTypeOfOwner");
		const isUnregistered =
			selectedInvestor &&
			selectedInvestor.getIn(["computed", "isUnregistered"]);
		let idTid = "foreign_owner_free_text";
		const validators = [required];
		const refPlaceholderTid = "generic.ssn_placeholder";
		let namePlaceholderTid = "generic.name_placeholder";

		const capitalInsuranceOptions = this.getCapitalInsuranceOptions();
		const deposOptions = this.getDeposOptions();
		const isCapitalInsurance =
			formValues?.investorTypeOfOwner === "capitalInsurance";

		const checkCapitalInsuranceFields = this.capitalInsuranceCheckAllFields();

		const typeOfOwner = formValues?.investorTypeOfOwner;

		if (isCapitalInsurance) {
			return (
				<>
					<Panel
						icon={<Landmark />}
						title={
							isModal
								? undefined
								: "investors.filter.investor_type_of_owner.capital_insurance"
						}
						type={panelType}
						mode={panelMode}
						marginBottom={!isModal}
					>
						<div
							style={isModal ? { margin: "0 -16px" } : { paddingTop: "16px" }}
						>
							<Field
								name="investorInformation.depositoryInstitute"
								translationId="investments.capital_insurance.depository_institution"
								clearable={false}
								className="form-control"
								options={deposOptions}
								onChange={this.onChange}
								validate={validators}
								component={renderReactSelectField}
							/>

							<Field
								name="investorInformation.id"
								translationId="investments.capital_insurance.insurance_company"
								clearable={false}
								className="form-control"
								onChange={this.onChangeCapitalInsurance}
								options={capitalInsuranceOptions}
								component={renderReactSelectField}
								validate={validators}
							/>

							<Field
								name="investorInformation.insuranceNr"
								type="text"
								translationId="investments.capital_insurance.insurance_nr"
								placeholder="investments.capital_insurance.insurance_nr"
								className="form-control"
								onChange={this.onChange}
								component={renderNameField}
								validate={validators}
							/>
						</div>
					</Panel>
					{checkCapitalInsuranceFields && (
						<Panel
							title="investments.capital_insurance.insurance_owner"
							icon={<User />}
							type={panelType}
							mode={panelMode}
							marginBottom={!isModal}
						>
							<div
								style={isModal ? { margin: "0 -16px" } : { paddingTop: "16px" }}
							>
								<Field
									name="captialIncuranceOwnerInformation.name"
									type="text"
									translationId="generic.name"
									className="form-control"
									placeholder="generic.name_placeholder"
									onChange={this.onChange}
									disabled={hasInvestorId}
									hasInvestorId={hasInvestorId}
									component={renderNameField}
								/>

								<Field
									name="captialIncuranceOwnerInformation.id"
									type="text"
									translationId="investors.personal_number_or_id"
									rightLabelComponent={this.renderManageIdToggleCapital}
									placeholder="generic.ssn_placeholder"
									className="form-control"
									onChange={this.onChange}
									disabled={hasSwedishIdCapital}
									hasSwedishId={hasSwedishIdCapital}
									component={renderIdField}
								/>

								<Field
									name="captialIncuranceOwnerInformation.email"
									type="email"
									disabled={hasInvestorId}
									labelTid="generic.email"
									placeholder="generic.email"
									className="form-control"
									onChange={this.onChange}
									hasInvestorId={hasInvestorId}
									hideInformButton={isModal}
									notified={
										selectedInvestor && selectedInvestor.get("notified")
									}
									objId={selectedInvestor && selectedInvestor.get("id")}
									isActiveUser={isActiveUser}
									isActiveCompany={isActiveCompany}
									isUnregistered={isUnregistered}
									onNotify={this.inviteShareholder}
									investorTypeOfOwner={typeOfOwner}
									component={renderInvestorEmailField}
								/>

								<Field
									name="captialIncuranceOwnerInformation.address"
									type="text"
									translationId="generic.address"
									placeholder="generic.address"
									className="form-control"
									onChange={this.onChange}
									component={renderField}
									disabled={addressFromProfile}
								/>

								<Field
									name="captialIncuranceOwnerInformation.zip"
									type="text"
									translationId="zip"
									placeholder="zip"
									className="form-control"
									onChange={this.onChange}
									component={renderField}
									disbled={addressFromProfile}
								/>

								<Field
									name="captialIncuranceOwnerInformation.city"
									type="text"
									translationId="generic.city"
									placeholder="generic.city"
									className="form-control"
									onChange={this.onChange}
									component={renderField}
									disabled={addressFromProfile}
								/>

								<div className="form-group col-md-12">
									<label className="control-label">{t("country")}</label>
									<CountrySelect
										onChange={this.onChangeCountryCaptialIncurance}
										value={
											selectedInvestor &&
											selectedInvestor.getIn([
												"captialIncuranceOwnerInformation",
												"country",
											])
										}
										placeholderTid="country"
										language={i18n.language}
										legacyDisabledColor={true}
									/>
								</div>

								<Field
									name="captialIncuranceOwnerInformation.telephone"
									type="text"
									translationId="generic.telephone"
									placeholder="generic.telephone_placeholder"
									className="form-control"
									onChange={this.onChange}
									component={renderField}
									disabled={phoneFromProfile}
								/>
							</div>
						</Panel>
					)}
				</>
			);
		}

		let panelIcon = "";
		let panelTitle = !isModal
			? t("shares.transaction.shareholders_modal.title")
			: "";
		if (!isModal && typeOfOwner === "company") {
			panelIcon = <Building2 />;
			panelTitle =
				formValues?.investorInformation.name || "shares.type_of_owner.company";
			idTid = "investors.org_number_or_id";
		} else if (!isModal && typeOfOwner === "private") {
			panelIcon = <User />;
			panelTitle =
				formValues?.investorInformation.name || "shares.type_of_owner.private";
			idTid = "investors.personal_number_or_id";
		}

		return (
			<Panel
				icon={panelIcon}
				title={panelTitle}
				type={panelType}
				mode={panelMode}
				marginBottom={!isModal}
			>
				<div style={isModal ? { margin: "0 -16px" } : { paddingTop: "16px" }}>
					{!typeOfOwner && (
						<div>
							<h2 className="mb-6 px-6 text-lg">
								{t("investors.investor_type_select.headline")}
							</h2>
							<div className="grid grid-cols-3 gap-6 p-6 pt-0">
								<Button
									variant="outline"
									className="aspect-video h-auto rounded-lg border-slate-300 [&>div]:flex [&>div]:flex-col [&>div]:items-center"
									onClick={() =>
										this.onChange(null, "private", null, "investorTypeOfOwner")
									}
								>
									<User size={32} />
									{t("shares.type_of_owner.private")}
								</Button>
								<Button
									variant="outline"
									className="h-auto rounded-lg border-slate-300 [&>div]:flex [&>div]:flex-col [&>div]:items-center"
									onClick={() =>
										this.onChange(null, "company", null, "investorTypeOfOwner")
									}
								>
									<Building2 size={32} />
									{t("shares.type_of_owner.company")}
								</Button>
								<Button
									variant="outline"
									className="h-auto rounded-lg border-slate-300 [&>div]:flex [&>div]:flex-col [&>div]:items-center"
									onClick={() =>
										this.onChange(
											null,
											"capitalInsurance",
											null,
											"investorTypeOfOwner",
										)
									}
								>
									<Landmark size={32} />
									{t(
										"investors.filter.investor_type_of_owner.capital_insurance",
									)}
								</Button>
							</div>
						</div>
					)}

					{typeOfOwner && (
						<div>
							<Field
								autoFocus
								name="investorInformation.id"
								type="text"
								hint={
									!hasSwedishId &&
									(typeOfOwner === "private"
										? t("investors.investor_details.id.private.hint")
										: t("investors.investor_details.id.company.hint"))
								}
								translationId={idTid}
								rightLabelComponent={this.renderManageIdToggle}
								placeholder={refPlaceholderTid}
								className="form-control"
								onChange={this.onChange}
								disabled={hasSwedishId}
								hasSwedishId={hasSwedishId}
								validate={validators}
								component={renderIdField}
							/>

							{/* Enter SSN or ORG NUMBER */}

							<Field
								name="investorInformation.email"
								type="email"
								disabled={hasInvestorId}
								labelTid={"generic.email"}
								placeholder="generic.email"
								className="form-control"
								onChange={this.onChange}
								hideInformButton={isModal}
								hasInvestorId={hasInvestorId}
								notified={selectedInvestor && selectedInvestor.get("notified")}
								objId={selectedInvestor && selectedInvestor.get("id")}
								isActiveUser={isActiveUser}
								isActiveCompany={isActiveCompany}
								isUnregistered={isUnregistered}
								onNotify={this.inviteShareholder}
								investorTypeOfOwner={typeOfOwner}
								component={renderInvestorEmailField}
							/>

							<Field
								name="investorInformation.name"
								type="text"
								translationId="generic.name"
								className="form-control"
								placeholder={namePlaceholderTid}
								onChange={this.onChange}
								disabled={hasInvestorId}
								hasInvestorId={hasInvestorId}
								investorTypeOfOwner={investorTypeOfOwner}
								component={renderNameField}
							/>

							<Field
								name="investorInformation.address"
								type="text"
								translationId="generic.address"
								placeholder="generic.address"
								className="form-control"
								onChange={this.onChange}
								disabled={addressFromProfile}
								component={renderField}
							/>

							<Field
								name="investorInformation.zip"
								type="text"
								translationId="zip"
								placeholder="zip"
								className="form-control"
								onChange={this.onChange}
								disabled={addressFromProfile}
								component={renderField}
							/>

							<Field
								name="investorInformation.city"
								type="text"
								translationId="generic.city"
								placeholder="generic.city"
								className="form-control"
								onChange={this.onChange}
								disabled={addressFromProfile}
								component={renderField}
							/>

							<div className="form-group col-md-12">
								<label className="control-label">{t("country")}</label>
								<CountrySelect
									onChange={this.onChangeCountry}
									value={
										selectedInvestor &&
										selectedInvestor.getIn(["investorInformation", "country"])
									}
									placeholderTid="country"
									disabled={addressFromProfile}
									language={i18n.language}
									legacyDisabledColor={true}
								/>
							</div>

							<Field
								name="investorInformation.telephone"
								type="text"
								translationId="generic.telephone"
								placeholder="generic.telephone_placeholder"
								className="form-control"
								onChange={this.onChange}
								disabled={phoneFromProfile}
								component={renderField}
							/>

							<Field
								name="investorInformation.bankInfo"
								type="text"
								translationId="generic.bank_info"
								placeholder="generic.bank_info_placeholder"
								className="form-control"
								onChange={this.onChange}
								component={renderField}
							/>

							{formValues?.investorTypeOfOwner ===
								INVESTOR_TYPE_OF_OWNER_COMPANY && (
								<Field
									name="investorInformation.contacts"
									onChange={this.onChange}
									component={this.renderContactBook}
								/>
							)}
						</div>
					)}
				</div>
			</Panel>
		);
	};

	renderNotes = () => {
		const { formValues, selectedInvestor, panelType, panelMode, isModal } =
			this.props;
		const investmentId = selectedInvestor && selectedInvestor.get("id");

		if (!formValues?.investorTypeOfOwner) return null;

		return (
			<Panel
				title="generic.notes"
				type={panelType}
				mode={panelMode}
				marginBottom={!isModal}
			>
				<Padding all={isModal ? 0 : 4} bottom={isModal ? 3 : 0}>
					<p />
					<EditorContainer
						fieldName={"investorInformation.notes"}
						objId={investmentId}
						contentState={
							selectedInvestor &&
							selectedInvestor.getIn(["investorInformation", "notes"])
						}
						onChange={this.onChangeEditor}
					/>
				</Padding>
			</Panel>
		);
	};

	/**
	 * The render function
	 * Renders The invitation field.
	 */
	renderInvitationField = () => {
		const { selectedInvestor } = this.props;
		if (selectedInvestor.getIn(["investorInformation", "accountStatus"])) {
			return null;
		}

		return (
			<RecommendInvonoPlatformField
				key={selectedInvestor.get("id")}
				invitationMessage="not_registered_text"
				emailType="refer-user-to-join-invono-as-an-added-shareholder-to-company"
			/>
		);
	};

	renderAttachments = () => {
		const { formValues, selectedInvestor, isModal } = this.props;
		const investmentId = selectedInvestor && selectedInvestor.get("id");

		if (!formValues?.investorTypeOfOwner) return null;

		return (
			<AttachmentsByObjIdContainer
				objType={OBJ_TYPE_INVESTMENT}
				objId={investmentId}
				nakedStyle={isModal}
				renderAttachmentDropdown={renderCommonAttachmentDropdown}
			/>
		);
	};

	renderRemindShareholderModalContainer = () => {
		return <RemindShareholderModalContainer />;
	};

	renderContent = () => {
		const { selectedInvestor, isModal, actionMode, formValues } = this.props;
		const isCapitalInsurance =
			formValues?.investorTypeOfOwner === "capitalInsurance";
		const checkCapitalInsuranceFields = this.capitalInsuranceCheckAllFields();

		if (actionMode === "EDIT" && !selectedInvestor) {
			return null;
		}

		if (isModal) {
			if (isCapitalInsurance) {
				return (
					<>
						{this.renderInvestor()}
						{checkCapitalInsuranceFields && (
							<>
								{this.renderNotes()}
								{this.renderAttachments()}
								{this.renderRemindShareholderModalContainer()}
							</>
						)}
					</>
				);
			}

			return (
				<>
					{this.renderInvestor()}
					{this.renderNotes()}
					{this.renderAttachments()}
					{this.renderRemindShareholderModalContainer()}
				</>
			);
		}

		if (isCapitalInsurance) {
			return (
				<>
					{this.renderInvestor()}
					{checkCapitalInsuranceFields && (
						<>
							{!isModal && this.renderShares()}
							{this.renderNotes()}
							{this.renderAttachments()}
							{this.renderRemindShareholderModalContainer()}
						</>
					)}
				</>
			);
		}

		return (
			<>
				{this.renderInvestor()}
				{!isModal && this.renderShares()}
				{this.renderNotes()}
				{this.renderAttachments()}
				{this.renderRemindShareholderModalContainer()}
			</>
		);
	};

	renderBody = () => {
		const { handleSubmit, isModal, dialogElement } = this.props;

		return (
			<>
				<form
					onSubmit={handleSubmit(this.onSubmit)}
					autoComplete="off"
					encType="multipart/form-data"
					className="h-full"
				>
					{isModal ? (
						this.renderContent()
					) : (
						<>
							{this.renderButtons()}
							<div
								ref={this.props.formRef}
								className="h-full overflow-auto pb-16"
							>
								{this.renderContent()}
							</div>
						</>
					)}
				</form>
				{dialogElement}
			</>
		);
	};

	render = () => {
		const { isModal, isOpen } = this.props;
		const { isLoading } = this.state;

		if (isModal) {
			return (
				<DialogModal
					open={isOpen}
					title={t("shares.transaction.shareholders_modal.title")}
					submitText={t("shares.transaction.shareholders_modal.title")}
					bodyRenderer={this.renderBody}
					onSubmit={this.saveAndCloseModal}
					onCancel={this.closeModal}
					isSubmitting={isLoading}
				/>
			);
		}

		return this.renderBody();
	};
}

const formId = "InvestorsDetails";
/** Map state to props. */
function mapStateToProps(state, ownProps) {
	return {
		user: state.user.get("userObj"),
		messages: state.i18n.messages,
		selectedInvestor: state.investors.get("selectedInvestor"),
		visibleInvestors: state.investors.get("visibleInvestors"),
		investors: state.investors.get("list"),
		hasAppliedFilters: state.investors.get("hasAppliedFilters"),
		company: state.company.company,
		formValues: getFormValues(formId)(state),
		initialValues: state.investors.get("selectedInvestor")
			? state.investors.get("selectedInvestor").toJS()
			: {},
		i18n: state.i18n,
		audit: state.audit.get("investors"),
		isModal: ownProps.mode === "MODAL",
		panelType: ownProps.mode === "MODAL" ? "inline" : undefined,
		panelMode: ownProps.mode === "MODAL" ? "transparent" : undefined,
		companyId: state.company.company.id,
		capitalInsurances: state.capitalInsurance.get("capitalInsurances"),
		depos: state.depos.get("depos"),
	};
}

const InvestorDetailsWithResolvedProps = withResolvedProps((props) => {
	const { confirm, dialogElement } = useDialogModal();
	const formRef = useRef(null);

	useEffect(() => {
		// formRef.current?.scrollIntoView({ behavior: "smooth" });
		if (!formRef.current) return;
		formRef.current.scrollTop = 0;
	}, [props.selectedInvestor?.get("id")]);

	return { confirm, dialogElement, formRef };
})(InvestorsDetails);

const InvestorsDetailsReduxForm = reduxForm({
	form: formId,
	enableReinitialize: true,
})(InvestorDetailsWithResolvedProps);

const mapActionsToProps = {
	fetchInvestor,
	editInvestor,
	deleteInvestor,
	archiveInvestor,
	editInvestorLocal,
	investorIsDirty,
	setSelectedInvestor,
	inviteInvestor,
	openModal,
	listCapitalInsurances,
	listDepos,
	listInvestors,
};

const InvestorsDetailsWithRouter = withRouter(
	connect(mapStateToProps, mapActionsToProps)(InvestorsDetailsReduxForm),
);

export default InvestorsDetailsWithRouter;
