import React from "react";
import { LoaderCircle } from "lucide-react";

const LoadingPanel = ({ size = 24 }) => {
	return (
		<div className="flex h-full w-full items-center justify-center">
			<LoaderCircle size={size} className="animate-spin text-slate-400" />
		</div>
	);
};

export default LoadingPanel;
