/**
 * Invoices
 * @module components/company/invoices/invoices
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { List } from "immutable";
import {
	fetchInvoices,
	createInvoicePdf,
} from "../../../actions/invoices.actions";
import Moment from "../../../modules/moment.module";
import Text from "../../../dumb-components/shared/text/text";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";

import { NUMBER_FORMAT_CURRENCY } from "/shared/constants";
import Panel from "@/dumb-components/shared/panel/panel";
import { ReceiptText } from "lucide-react";

class Invoices extends Component {
	componentDidMount = () => {
		const { fetchInvoices } = this.props;
		fetchInvoices();
	};

	onClickShowInvoice = (invoice) => {
		const win = window.open(
			`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`,
			"_blank",
		);
		win.location = invoice.get("hosted_invoice_url");
	};

	renderHeader = () => {
		return (
			<thead>
				<tr>
					<th>
						<FormattedMessage id="subscriptions.invoice.due_date" />
					</th>
					<th>
						<FormattedMessage id="subscriptions.invoice.amount" />
					</th>
					<th>
						<FormattedMessage id="subscriptions.invoice.number" />
					</th>
					<th>
						<FormattedMessage id="subscriptions.invoice.status" />
					</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
		);
	};

	renderRow = (invoice, index) => {
		const isUpcoming = invoice.get("billing_reason") === "upcoming";
		return (
			<tr key={index}>
				<td>{Moment.unix(invoice.get("date")).format("ll")}</td>
				<td>
					{localeFormatNumber(
						invoice.get("total") / 100,
						NUMBER_FORMAT_CURRENCY,
					)}
				</td>
				<td>{invoice.get("number")}</td>
				<td>
					{invoice.get("paid") ? <Text tid="subscriptions.invoice.paid" /> : ""}
				</td>

				<td className="text--align-right">
					{!isUpcoming && (
						<button
							type="button"
							className="btn btn-default"
							onClick={this.onClickShowInvoice.bind(this, invoice)}
						>
							<FormattedMessage id="generic.open" />
						</button>
					)}
				</td>
			</tr>
		);
	};

	render = () => {
		const { invoicesList } = this.props;
		return (
			<div className="flex h-full flex-col p-md">
				<Panel icon={<ReceiptText />} title="invoices.breadcrumbs.title">
					<div className="p-md">
						<table className="table-striped table--vert-mid table">
							{this.renderHeader()}
							<tbody>
								{invoicesList &&
									invoicesList.size > 0 &&
									invoicesList.map(this.renderRow)}
								{(!invoicesList || invoicesList.size === 0) && (
									<tr>
										<td colSpan="5">
											<FormattedMessage id="subscriptions.invoice.no_invoices" />
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</Panel>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		invoicesList: state.invoices.get("invoicesList", List()),
	};
}

export default connect(mapStateToProps, { fetchInvoices, createInvoicePdf })(
	Invoices,
);
